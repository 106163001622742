// arrays.ts

export function transpose<T>(matrix: T[][]): T[][] {
    return matrix[0].map((_, colIndex) => matrix.map(row => row[colIndex]));
}

export function arrayTo2D<T>(arr: T[], x: number, y: number): T[][] {
    if (arr.length !== x * y) {
        throw new Error(`Array length (${arr.length}) does not match dimensions ${x}x${y}`);
    }

    const result: T[][] = [];
    for (let i = 0; i < x; i++) {
        result.push(arr.slice(i * y, (i + 1) * y));
    }

    return result;
}

export function matrixFrom2D<T>(matrix: T[][]): T[] {
    return matrix.reduce((acc, row) => acc.concat(row), []);
}
  