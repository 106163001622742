import { FrontEndModel } from '../FrontEndModel';
import { BASchoolDivision, SportGender, SportName, Gender } from "@/../types/enums";
import { CoachDetailModel } from "../school/SchoolModel";
import { SocialMediaField } from "@/../types/interfaces";
import { BALocationModel } from "@/models"
import { linkify, genderTextPlural, isGenderMatch, isEmpty, isNotEmptyArray } from '@/pipes';
import { httpUrl } from '@/helpers';

export class BASportDetailModel {
	name: SportName = SportName.Soccer;
	gender: SportGender = SportGender.MEN;
	division: string = '';
	conference: string = '';

	facility: string = '';
	coaches: CoachDetailModel[] = [];

	socialMedia: SocialMediaField[] = [];

	load<T = this>(obj: Partial<T>): this {
		Object.assign(this, obj);
		return this;
	}

  get Name(): string {
    return `${genderTextPlural(this.gender, {adult: true})} ${this.name}`;
  }
  get HeadCoach(): CoachDetailModel {
    if( !this.HasCoaches ) return undefined;
    
    const headCoach = this.Coaches.find(c => c.title.toLowerCase().includes('head') == true);
    if( !!headCoach ) return headCoach;
    
    const director = this.Coaches.find(c => c.title.toLowerCase().includes('director') == true);
    if( !!director ) return director;
  }
  get HasHeadCoach(): boolean {
    return !!this.HeadCoach;
  }
  get HasCoaches(): boolean {
    return( !!this.Coaches && this.Coaches.length > 0);
  }
	get Coaches(): CoachDetailModel[] {
    const uniqueCoaches = this.coaches.reduce((accumulator, current) => {
      if (!accumulator.find((item) => item.email === current.email)) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);

    return uniqueCoaches;
  }
	get CoachesWithEmails(): string[]{
		if( !this.HasCoaches ) return [];
		return this.Coaches.map(x => x.email)
	}
  get Staff(): CoachDetailModel[] {
    if( !this.HasCoaches ) return [];
    if( !this.HasHeadCoach ) return this.Coaches;

    const headCoach = this.HeadCoach;
    return this.Coaches.filter(c => c.email != headCoach.email);
  }
  get HasStaff(): boolean {
    if( !this.Staff ) return false;

    return this.Staff.length > 0;
  }
	findCoach(email: string): string{
		if( !this.HasCoaches ) return '';
		const coach = this.Coaches.find(x => x.email === email);
		return coach.name + ' | ' + coach.title
	}
}

enum BASchoolLogos {
  NCAA = 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/dd/NCAA_logo.svg/1200px-NCAA_logo.svg.png',
  NAIA = 'https://upload.wikimedia.org/wikipedia/commons/c/c0/National_Association_of_Intercollegiate_Athletics_logo.svg',
  NCCAA = 'https://upload.wikimedia.org/wikipedia/commons/b/b4/Nccaa_christian_college_logo.png',
  CCCAA = 'https://upload.wikimedia.org/wikipedia/commons/1/18/CCCAA_logo.jpg?20130131235442',
  NJCAA = 'https://upload.wikimedia.org/wikipedia/en/f/fd/National_Junior_College_Athletic_Association_logo.png',
  NWAC = 'https://www.nwacsports.org/images/setup/PRIMARY_LOGO-0.3x.png',
  USPORT = 'https://upload.wikimedia.org/wikipedia/commons/3/34/U_Sports_Logo.svg',
  CCAA = 'https://cdn.bestathletes.co/school-logos/097b03e5-ee3c-4bf4-ac55-e56b036c9e90.jpg',
}

export class BASchoolModel extends FrontEndModel {
    ipedsId: string = '';
    opeId: string = '';
    cd_schoolID: string = '';           // https://www.collegiatedirectories.com/
    ncaaId: string = '';                // https://web3.ncaa.org/directory
    // basic information
    name: string = '';
    phone: string = '';
    logoUrl: string = '';
    logoUrl_athletics: string = '';
    location: BALocationModel = new BALocationModel;
    nickname: string = '';
    colors: string[] = [];
    facebook: string = '';
    twitter: string = '';
    instagram: string = '';
    // general
    phoneNum: string = '';
    website: string = '';
    website_athletics: string = '';
    schoolType: string = '';
    awardsOffered: string = '';
    campusSetting: string = '';
    campusHousing: string = '';
    studentPopulation: string = '';
    studentToFaculty: string = '';
    // links
    links_admissions: string = '';
    links_apply: string = '';
    links_financialAid: string = '';
    links_netPriceCalc: string = '';
    links_vetTuition: string = '';
    links_disabilityServices: string = '';
    links_athleticGrad: string = '';
    // Tuition - TuitionYear
    tuitionYear_general: string = '';
    tuitionYear_inState: string = '';
    tuitionYear_outOfState: string = '';
    // Tuition - lodgings
    lodgings_onCampus: string = '';
    lodgings_offCampus: string = '';
    // FinancialAid - general
    financialAid_general_recipients: string = '';
    financialAid_general_percentAwarded: string = '';
    financialAid_general_total: string = '';
    financialAid_general_average: string = '';
    // FinancialAid - grant
    grant_recipients: string = '';
    grant_percentAwarded: string = '';
    grant_total: string = '';
    grant_average: string = '';
    // FinancialAid - federalGrant
    federalGrant_recipients: string = '';
    federalGrant_percentAwarded: string = '';
    federalGrant_total: string = '';
    federalGrant_average: string = '';
    // FinancialAid - pellGrant
    pellGrant_recipients: string = '';
    pellGrant_percentAwarded: string = '';
    pellGrant_total: string = '';
    pellGrant_average: string = '';
    // FinancialAid - otherFederalGrant
    otherFederalGrant_recipients: string = '';
    otherFederalGrant_percentAwarded: string = '';
    otherFederalGrant_total: string = '';
    otherFederalGrant_average: string = '';
    // FinancialAid - stateGrant
    stateGrant_recipients: string = '';
    stateGrant_percentAwarded: string = '';
    stateGrant_total: string = '';
    stateGrant_average: string = '';
    // FinancialAid - institutionalGrant
    institutionalGrant_recipients: string = '';
    institutionalGrant_percentAwarded: string = '';
    institutionalGrant_total: string = '';
    institutionalGrant_average: string = '';
    // FinancialAid - studentLoan
    studentLoan_recipients: string = '';
    studentLoan_percentAwarded: string = '';
    studentLoan_total: string = '';
    studentLoan_average: string = '';
    // FinancialAid - federalStudentLoan
    federalStudentLoan_recipients: string = '';
    federalStudentLoan_percentAwarded: string = '';
    federalStudentLoan_total: string = '';
    federalStudentLoan_average: string = '';
    // FinancialAid - otherStudentLoan
    otherStudentLoan_recipients: string = '';
    otherStudentLoan_percentAwarded: string = '';
    otherStudentLoan_total: string = '';
    otherStudentLoan_average: string = '';
    // Admissions - numApplicants
    numApplicants_total: string = '';
    numApplicants_male: string = '';
    numApplicants_female: string = '';
    // Admissions - percentAdmitted
    percentAdmitted_total: string = '';
    percentAdmitted_male: string = '';
    percentAdmitted_female: string = '';
    // Admissions - percentEnrolled
    percentEnrolled_total: string = '';
    percentEnrolled_male: string = '';
    percentEnrolled_female: string = '';
    // Admissions - admissionConsideration
    admissions_gpa: string = '';
    admissions_rank: string = '';
    admissions_record: string = '';
    admissions_prep: string = '';
    admissions_recommendation: string = '';
    admissions_formalDemo: string = '';
    admissions_experience: string = '';
    admissions_essay: string = '';
    admissions_legacy: string = '';
    admissions_satact: string = '';
    admissions_other: string = '';
    admissions_englishProficiency: string = '';
    // Admissions - testScores - satEnglish
    admissions_satEnglish_percentile25: string = '';
    admissions_satEnglish_percentile50: string = '';
    admissions_satEnglish_percentile75: string = '';
    // Admissions - testScores - satMath
    admissions_satMath_percentile25: string = '';
    admissions_satMath_percentile50: string = '';
    admissions_satMath_percentile75: string = '';
    // Admissions - testScores - actComposite
    admissions_actComposite_percentile25: string = '';
    admissions_actComposite_percentile50: string = '';
    admissions_actComposite_percentile75: string = '';
    // Admissions - testScores - actEnglish
    admissions_actEnglish_percentile25: string = '';
    admissions_actEnglish_percentile50: string = '';
    admissions_actEnglish_percentile75: string = '';
    // Admissions - testScores - actMath
    admissions_actMath_percentile25: string = '';
    admissions_actMath_percentile50: string = '';
    admissions_actMath_percentile75: string = '';

    gradRate: string = '';
    popMajors: string[] = [];

    sports: BASportDetailModel[] = [];
    hasSports: boolean = false;

    load<T = this>(obj: Partial<T>): this {
      Object.assign(this, obj);
      if(obj['location']) {
        this.location = new BALocationModel;
        Object.assign(this.location, obj['location']);
      }
      if(obj['sports']) {
        const sports: BASportDetailModel[] = obj['sports'];
        this.sports = sports.map(s => { 
          var sport = new BASportDetailModel;
          Object.assign(sport, s);
          return sport;
        })
      }
      return this;
    }

     get Location(): any {
      if( !this.location ) return undefined;
      return this.location.Location;
    }
    get Website(): string {
      if( !this.website ) return `https://www.google.com/search?q=${this.name}`;
      return linkify(this.website);
    }
    get NumSports(): number {
      if( !this.sports ) return 0;
      return this.sports.length;
    }
    get HasSports(): boolean {
      return this.NumSports > 0;
    }
    HasSport(sport: SportName, gender: SportGender): boolean {
      return !!this.Sport(sport, gender);
    }
    Sport(sport: SportName, gender: SportGender | Gender): BASportDetailModel {
      if( !this.HasSports ) return undefined;
  
      const sports = this.sports.filter(s => sport === s.name);
      return sports.find(s => isGenderMatch(s.gender, gender));
    }
    get division(): string {
      if( !this.HasSports ) return '';
      for( const sport of this.sports ) {
        if( !!sport.division && sport.division.length > 0 ) return sport.division
      }
      return '';
    }
    get Division(): string {
      return this.division.replace('1', 'I').replace('2', 'II').replace('3', 'III');
    }
    get Conference(): string {
      if( !this.HasSports ) return '';
      for( const sport of this.sports ) {
        if( sport.conference && sport.conference.length > 0 ) return sport.conference;
      }
    }

    get Facebook(): string {
      if( !this.facebook || this.facebook.length === 0 ) return httpUrl('facebook.com');
      if( this.facebook.includes('http')) return this.facebook;
      return httpUrl('facebook.com', this.facebook);
    }
    get Twitter(): string {
      if( !this.twitter || this.twitter.length === 0 ) return httpUrl('twitter.com');
      if( this.twitter.includes('http')) return this.twitter;
      return httpUrl('twitter.com', this.twitter);
    }
    get Instagram(): string {
      if( !this.instagram || this.instagram.length === 0 ) return httpUrl('instagram.com');
      if( this.instagram.startsWith('@') ) this.instagram = this.instagram.slice(1);
      if( this.instagram.includes('http')) return this.instagram;
      return httpUrl('instagram.com', this.instagram);
    }
    get ipedURL(): string {
      const ipedUrl = `https://nces.ed.gov/collegenavigator/?q=s=all&id=`;
      return `${ipedUrl}${this.ipedsId}`;
    }
    get HasLogo(): boolean {
      return !isEmpty(this.logoUrl);
    }
    get HasPopularMajors(): boolean {
      return isNotEmptyArray(this.popMajors);
    }
    get HasLinks(): boolean {
      return !isEmpty(this.links_admissions) ||
              !isEmpty(this.links_apply) ||
              !isEmpty(this.links_athleticGrad) ||
              !isEmpty(this.links_disabilityServices) ||
              !isEmpty(this.links_financialAid) ||
              !isEmpty(this.links_netPriceCalc) ||
              !isEmpty(this.links_vetTuition);
    }
    get HasSAT(): boolean {
      return !isEmpty(this.admissions_satMath_percentile25) ||
      !isEmpty(this.admissions_satMath_percentile50) ||
      !isEmpty(this.admissions_satMath_percentile75) ||
      !isEmpty(this.admissions_satEnglish_percentile25) ||
      !isEmpty(this.admissions_satEnglish_percentile50) ||
      !isEmpty(this.admissions_satEnglish_percentile75)
    }
    get HasACT(): boolean {
      return !isEmpty(this.admissions_actMath_percentile25) ||
      !isEmpty(this.admissions_actMath_percentile50) ||
      !isEmpty(this.admissions_actMath_percentile75) ||
      !isEmpty(this.admissions_actEnglish_percentile25) ||
      !isEmpty(this.admissions_actEnglish_percentile50) ||
      !isEmpty(this.admissions_actEnglish_percentile75)
    }
    get PopularMajors(): string[] {
      return this.popMajors.filter(m => m.trim().startsWith('General') === false).filter(m => m.trim().startsWith('Other') === false);
    }

    get LogoURL(): string {
      if( this.HasLogo ) return this.logoUrl;

      switch(this.division) {
        case BASchoolDivision.NCAA_1:
        case BASchoolDivision.NCAA_2:
        case BASchoolDivision.NCAA_3:
          return BASchoolLogos.NCAA;
        case BASchoolDivision.NAIA:
          return BASchoolLogos.NAIA;
        case BASchoolDivision.NCCAA:
          return BASchoolLogos.NCCAA;
        case BASchoolDivision.CCCAA:
          return BASchoolLogos.CCCAA;
        case BASchoolDivision.NJCAA:
        case BASchoolDivision.NJCAA_2:
        case BASchoolDivision.NJCAA_3:
          return BASchoolLogos.NJCAA;
        case BASchoolDivision.NWAC:
          return BASchoolLogos.NWAC;
        case BASchoolDivision.USPORT:
          return BASchoolLogos.USPORT;
        case BASchoolDivision.CCAA:
          return BASchoolLogos.CCAA;
        }
      return '';
    }
}