import { CrudApi } from './CrudApi';
import { CalendarModel } from '@/models/calendar/CalendarModel';
import { CalendarEventModel } from '@/models/calendar/CalendarEventModel';
import { getBrowserTimezone } from '@/helpers/date';
import { QueryOptions } from '@/../types/interfaces';
import { AxiosRequestConfig } from 'axios';
import { rootUrl } from "./api";
export class CalendarApi extends CrudApi<CalendarModel>{
	constructor(protected parentResource: string, protected parentId: string) {
		super(`${parentResource}/${parentId}/calendar`, (obj) => new CalendarModel(parentId).load(obj));
	}
	async findOrCreateCalendarByName(name?: string, options: QueryOptions = {}, config: AxiosRequestConfig = {}): Promise<CalendarModel> {
		try {
			const obj = await CrudApi.Api((c) => c.get(`/${this.resource}/findOrCreateCalendarByName`, this.applyOptionsToRequest(options, {
				...config,
				params:{
					name,
					timezone: getBrowserTimezone(),
					...config.params,
				}
			})));
			return this.create(obj);
		} catch (e) {
			if (e.response.status === 404) {
				return null;
			}
			throw e;
		}
	}

	generateICSURL(id: string): string {
		return rootUrl + `/calendar/ics/${id}`;
	}
}

export class CalendarEventsApi extends CrudApi<CalendarEventModel>{
	constructor(protected parentResource: string, protected parentId: string, protected calendarId: string) {
		super(`${parentResource}/${parentId}/calendar/${calendarId}/calendarEvent`, (obj) => new CalendarEventModel(calendarId).load(obj));
	}
	
	async deleteByRootId(rootId: string, config?: AxiosRequestConfig): Promise<any>{
		const res = await CrudApi.Api((c) => c.delete(`/${this.resource}/delete-by-root-id/${rootId}`, config));
		return res;
	}

	async pruneByRootId(rootId: string, after: Date, config?: AxiosRequestConfig): Promise<any> {
		const res = await CrudApi.Api((c) => c.post(`/${this.resource}/prune/${rootId}`, { after }, config));
		return res;
	}

	async setRecurrenceUntilByRootId(rootId:string, until: Date, config?: AxiosRequestConfig): Promise<any> {
		const res = await CrudApi.Api((c) => c.post(`/${this.resource}/set-until-by-root-id/${rootId}`, { until }, config));
		return res;
	}
}