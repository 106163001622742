// ObjectCache.ts

export class ObjectCache<T extends { id: string }> {
	private cache: Record<string, T>;

	constructor() {
		this.cache = {};
	}

	get(id: string): T | undefined {
		if( !id ) return undefined;
		return this.cache[id];
	}

	getMultiple(ids: string[]): (T | undefined)[] {
		return ids.map(id => this.get(id));
	}

	set(obj: T): void {
		if( !obj ) return;
		this.cache[obj.id] = obj;
	}

	setMultiple(objs: T[]): void {
		objs.forEach(obj => this.set(obj));
	}

	clear(): void {
		this.cache = {}; // Recreate the object to clear it.
	}

	get length(): number {
		return Object.keys(this.cache).length;
	}
}
