import { fullName, isEmpty, isNotEmpty } from '@/pipes';
import { AccessControlledModel } from '../AccessControlledModel';
import { ScoutingReportPlayerDetails, ScoutingReportGameDetails, ScoutingReportEvaluationMatrix, ContactInfo, ScoutingReportEvaluationMatrixNotes, ScoutingReportScore } from '@best-athletes/ba-types';
import { AthleteReportCategory } from '../athleteReport/AthleteReportModel';
import { AthleteProfileModel, CoachProfileModel } from '@/models';
import { coachApi } from '@/api/CoachApi';
import { athleteApi } from '@/api/AthleteApi';

export class ScoutingReportEvaluation {
	athleteAttributes: Array<AthleteReportCategory>;
	gameMoments: Array<AthleteReportCategory>;
	scoutingEval: Array<AthleteReportCategory>;
}

export class ScoutingReportModel extends AccessControlledModel{
	version: number = 3;
	createdAt: Date = new Date();
	athleteId?: string | null = null;
	coachId: string = undefined;
	contactInfo: ContactInfo | null = null;
	lastModified: Date | null = null;
	anonymousCoach: boolean = false;

	playerDetails: ScoutingReportPlayerDetails;
	gameDetails: ScoutingReportGameDetails;
	evaluationMatrix: ScoutingReportEvaluationMatrix;
	evaluationMatrixNotes: ScoutingReportEvaluationMatrixNotes;

	evaluation: ScoutingReportEvaluation;
	scoutingReportLabels: Array<string> = [];
	scoutingReportMoments: Array<string> = [];
	scoutingReportEvaluations: Array<string> = [];

	submitted: boolean = false;

	constructor() {
		super();
		this.createdAt = new Date;
		this.athleteId = undefined;
		this.contactInfo = {
			firstName: '',
			lastName: '',
			email: '',
			lastContact: new Date,
		};
		this.lastModified = new Date;
		this.anonymousCoach = false;
		this.playerDetails = {
			firstName: '',
			lastName: '',
			position: '',
			jerseyNumber: 0,
		};
		this.gameDetails = {
			coachId: '',
			evaluationDate: new Date,
		}
		this.evaluation = new ScoutingReportEvaluation;
		this.evaluationMatrix = {
			technical: null,
			tactical: null,
			physical: null,
			mental: null,
			attackingOrganization: null,
			attackingTransition: null,
			attackingSetPlays: null,
			defendingOrganization: null,
			defendingTransition: null,
			defendingSetPlays: null,
			competitive: null,
			resilience: null,
			intelligence: null,
			speed: null,
			presence: null,
			performanceScore: null,
			potentialScore: null,
		};
		this.evaluationMatrixNotes = {
			technical: '',
			tactical: '',
			physical: '',
			mental: '',
			attackingOrganization: '',
			attackingTransition: '',
			attackingSetPlays: '',
			defendingOrganization: '',
			defendingTransition: '',
			defendingSetPlays: '',
			competitive: '',
			resilience: '',
			intelligence: '',
			speed: '',
			presence: '',
			performanceScore: '',
			potentialScore: '',
		};
		this.submitted = false;
	}
	/** True if all steps of the Evaluation Matrix have been given a grade */
	get IsComplete(): boolean{
		return this.submitted;
	}

	get PlayerName(): string | null{
		if(this.playerDetails.firstName && this.playerDetails.lastName){
			return `${this.playerDetails.firstName} ${this.playerDetails.lastName}`;
		}
		return null;
	}

	get AthleteId(): string {
		return this.athleteId;
	}
	get CoachId(): string {
		if( isNotEmpty( this.gameDetails?.coachId ) ) return this.gameDetails.coachId;
		return this.coachId;
	}

	get Performance(): AthleteReportCategory {
		return {
			name: 'Performance',
			rating: this.evaluationMatrix?.performanceScore,
			notes: this.evaluationMatrixNotes.performanceScore,
		}
	}
	get Potential(): AthleteReportCategory {
		return {
			name: 'Potential',
			rating: this.evaluationMatrix?.potentialScore,
			notes: this.evaluationMatrixNotes.potentialScore,
		}
	}

	get HasContactInfo(): boolean{
		if(this.contactInfo === null) return false;
		return this.contactInfo.email !== "";
	}

	EvaluationStr(item: string, fourPlusThreshold: number = 4.99): string {
		if( isEmpty( this.evaluationMatrix[item] ) ) return '';
		return this.evaluationMatrix[item] > fourPlusThreshold? '4+' : this.evaluationMatrix[item].toFixed(1);
	}
	ScoreLevel(score: ScoutingReportScore ): string {
		switch( score ) {
			case ScoutingReportScore.Score1:
				return 'Inferior';
			case ScoutingReportScore.Score2:
				return 'Ordinary';
			case ScoutingReportScore.Score3:
				return 'Average';
			case ScoutingReportScore.Score4:
				return 'Excellent';
			case ScoutingReportScore.Score4Plus:
				return 'Outstanding';
			}
	}
	GetCategoryDescriptor(category: string): string {
		switch( category ) {
			case 'potentialScore': return  `overall athlete at this level`;
			case 'technical': return `technical player`;
			case 'tactical': return `tactical outlook`;
			case 'physical': return `physical presence`;
			case 'mental': return `mindset`;
			case 'attackingOrganization': return `plan while organizing the attack`;
			case 'attackingTransition': return `transition execution`;
			case 'attackingSetPlays': return `preparation during attacking set pieces`;
			case 'defendingOrganization': return `organization while defending`;
			case 'defendingTransition': return `execution transitioning to defence`;
			case 'defendingSetPlays': return `presence while defending set pieces`;
			case 'competitive': return `competitive spirit`;
			case 'resilience': return `resilience`;
			case 'intelligence': return `mindset and outlook`;
			case 'speed': return `speed of execution`;
			case 'presence': return `presence of the field`;
				}
	}
	GetNote(category: string): string {
		if( !this.evaluationMatrixNotes[category] ) return `An ${this.ScoreLevel(this.evaluationMatrix[category])} ${this.GetCategoryDescriptor(category)}`;
		return this.evaluationMatrixNotes[category];
	}
	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		if(obj['createdAt']) this.createdAt = new Date(obj['createdAt']);
		if (obj['lastModified'] !== null) this.lastModified = new Date(obj['lastModified']);
		if (obj['playerDetails'] && obj['playerDetails']['dateOfBirth']){
			this.playerDetails.dateOfBirth = new Date(obj['playerDetails']['dateOfBirth']);
		}
		if (obj['gameDetails'] && obj['gameDetails']['evaluationDate']){
			this.gameDetails.evaluationDate = new Date(obj['gameDetails']['evaluationDate']);
		}
		if (obj['evaluationMatrixNotes'] === undefined) {
			this.evaluationMatrixNotes = {
				technical: null,
				tactical: null,
				physical: null,
				mental: null,
				attackingOrganization: null,
				attackingTransition: null,
				attackingSetPlays: null,
				defendingOrganization: null,
				defendingTransition: null,
				defendingSetPlays: null,
				competitive: null,
				resilience: null,
				intelligence: null,
				speed: null,
				presence: null,
				performanceScore: null,
				potentialScore: null,
			}
		}
		if (obj['playerDetails']['competitiveLevel'] == undefined) {
			this.playerDetails.competitiveLevel = null;
		}
		return this;
	}

	get FourCornerAvailable(): boolean{
		if( !this.evaluationMatrix ) return false;
		return( !!this.evaluationMatrix.technical && !!this.evaluationMatrix.tactical && !!this.evaluationMatrix.physical && !!this.evaluationMatrix.mental );
	}
	get FourMomentAvailable(): boolean {
		if( !this.evaluationMatrix ) return false;
		if (this.evaluationMatrix.attackingOrganization && this.evaluationMatrix.attackingTransition && this.evaluationMatrix.defendingOrganization && this.evaluationMatrix.defendingTransition){
			return true
		}
		return false
	}
	get SixMomentAvailable(): boolean {
		if( !this.evaluationMatrix ) return false;
		if (this.FourMomentAvailable && this.evaluationMatrix.attackingSetPlays && this.evaluationMatrix.defendingSetPlays){
			return true
		}
		return false
	}
	get CRISPAvailable(): boolean{
		if( !this.evaluationMatrix ) return false;
		if (this.evaluationMatrix.competitive && this.evaluationMatrix.resilience && this.evaluationMatrix.intelligence && this.evaluationMatrix.speed && this.evaluationMatrix.presence){
			return true
		}
		return false
	}
	get OverallChartAvailable(): boolean{
		return this.FourCornerAvailable && this.SixMomentAvailable && this.CRISPAvailable;
	}

	get FourCornersLabels(): string[]{
		return ['Technical', 'Tactical', 'Physical', 'Mental'];
	}
	get SixMomentEvaluationLabels(): string[]{
		return [
			'Att Org', 'Att Trans', 'Att Set Plays',
			'Def Org', 'Def Trans', 'Def Set Plays'
		];
	}
	get CRISPEvaluationLabels(): string[]{
		return ['Competitive', 'Resilience', 'Intelligence', 'Speed', 'Presence'];
	}
	get OverallChartLabels(): string[]{
		return [
			...this.FourCornersLabels,
			...this.SixMomentEvaluationLabels,
			...this.CRISPEvaluationLabels,
		]
	}

	get FourCornersValues(): number[]{
		if (!this.FourCornerAvailable) return [];
		return [
			this.evaluationMatrix.technical,
			this.evaluationMatrix.tactical,
			this.evaluationMatrix.physical,
			this.evaluationMatrix.mental,
		]
	}
	get SixMomentValues(): number[]{
		if (!this.SixMomentAvailable) return [];
		return  [
			this.evaluationMatrix.attackingOrganization,
			this.evaluationMatrix.attackingTransition,
			this.evaluationMatrix.attackingSetPlays,
			this.evaluationMatrix.defendingOrganization,
			this.evaluationMatrix.defendingTransition,
			this.evaluationMatrix.defendingSetPlays,
		]
	}
	get CRISPValues(): number[]{
		if (!this.CRISPAvailable) return [];
		return [
			this.evaluationMatrix.competitive,
			this.evaluationMatrix.resilience,
			this.evaluationMatrix.intelligence,
			this.evaluationMatrix.speed,
			this.evaluationMatrix.presence
		]
	}
	get OverallChartValues(): number[]{
		return [
			...this.FourCornersValues,
			...this.SixMomentValues,
			...this.CRISPValues,
		]
	}

}

export class ScoutingReportModelEx extends ScoutingReportModel {
	athlete: Partial<AthleteProfileModel>;
	coach: Partial<CoachProfileModel>;

	constructor(report: ScoutingReportModel, athlete?: Partial<AthleteProfileModel>, coach?: Partial<CoachProfileModel>) {
		super();
		Object.assign(this, report);
		this.athlete = athlete;
		this.coach = coach;
	}
	static async fromScoutingReport(report: ScoutingReportModel, athletes?: Array<AthleteProfileModel>, coaches?: Array<CoachProfileModel>): Promise<ScoutingReportModelEx> {
		var athlete: AthleteProfileModel = undefined;
		var coach: CoachProfileModel = undefined;
		if( isNotEmpty(report.AthleteId) ) {
			// has this athlete already been loaded from database?
			if( isNotEmpty(athletes) ) athlete = athletes.find(a => a.id === report.AthleteId);

			// if athlete is not found, load from database
			if( isEmpty(athlete) ) {
				athlete = await athleteApi.getPublicProfile(report.AthleteId);
				// add to loaded athletes array
				if( athletes != undefined ) athletes.push(athlete);
			}
		}
		if( isNotEmpty(report.CoachId) ) {
			if( isNotEmpty(coaches) ) coach = coaches.find(c => c.id === report.CoachId);
			if( isEmpty(coach) ) {
				coach = await coachApi.findById( report.CoachId );
				if( coaches != undefined ) coaches.push(coach);
			}
		}
		return new ScoutingReportModelEx(report, athlete, coach);
	}
	get AthleteFullName(): string {
		return this.athlete? this.athlete.FullName : fullName(this.contactInfo.firstName, this.contactInfo.lastName)
	}
}
