import { OrganizationType } from "@/../types/enums";
import { AccessControlledModel } from '../AccessControlledModel';
import { StaffMember } from '../team/StaffMember';
import { TeamJersey, TeamModel } from '../team/TeamModel';
import { AthleteReportCategoryHeading } from '@/models/athleteReport/AthleteReportModel';
import { PlayerOnOrg } from "../team";
import { isEmpty } from "@/pipes";

export class OrgField {
	key: string = "";
	type: string = "";
	details: string = "";
}
export class OrganizationLandingPage {
	title: string = "";
	bannerUrl: string = "";
	info: string = "";
	instructions: string = "";
	fields: Array<OrgField> = [];
	butOK: string = "";
	butCancel: string = "";
	footerUrl: string = "";
}
export class OrganizationModel extends AccessControlledModel {
	name: string = "";
	type: OrganizationType = OrganizationType.Club;
	sport: string = "";
	country: string = "";
	city: string = "";
	league: string = "";
	logoUrl: string = "";
	orgUrl: string = "";
	bio: string = "";
	colors?: Array<string>;
	jerseyPrimary?: TeamJersey;
	jerseyAlternate?: TeamJersey;
	jerseyOther?: TeamJersey;
	staffMembers: StaffMember[] = [];
	teams: Array<TeamModel | string> = [];
	members: Array<PlayerOnOrg> = [];
	athleteReportHeadings: Array<AthleteReportCategoryHeading> = [];
	scoutingReportLabels: Array<string> = [];
	scoutingReportMoments: Array<string> = [];
	scoutingReportEvaluations: Array<string> = [];
	landingPage: OrganizationLandingPage = new OrganizationLandingPage;

	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		if( isEmpty(this.colors) ) {
			this.colors = ['#FF0000FF', '#0000BBFF', '#FFFFFFFF'];
		}
		if( isEmpty(this.jerseyPrimary) ) {
			this.jerseyPrimary = {
				label: 'Primary',
				color: '#FF0000FF',
				accent: "#FFFFFFFF",
				number: "#FFFFFFFF",
			};
		}
		if( isEmpty(this.jerseyAlternate) ) {
			this.jerseyAlternate = {
				label: 'Alternate',
				color: '#0000BBFF',
				accent: "#FFFFFFFF",
				number: "#FFFFFFFF",
			};
		}
		if( isEmpty(this.jerseyOther) ) {
			this.jerseyOther = {
				label: 'Goalkeeper',
				color: '#007F0EFF',
				accent: "#FFFFFFFF",
				number: "#FFFFFFFF",
			};
		}
		return this;
	}

	get PopulatedTeams(): TeamModel[] {
		const populated = this.teams.filter(doc => (typeof doc !== 'string'));
		return <TeamModel[]>populated;
	}

	get TeamCount(): number{
		return this.teams.length;
	}
	get AccountStatus(): string{
		return 'Active';
	}

	get Location(): string{
		if(this.city && this.country) return `${this.city}, ${this.country}`;
		return this.city;
	}

	get FullBio(): string{
		if( this.bio !== "" ) return this.bio;
		return `${this.name} - ${this.Location}`
	}
}