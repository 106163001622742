
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BAIconsMixin, StringsMixin, VuetifyMixin } from '@/mixins';

@Component({
    components: {}
})
export default class ArrayItemsEditDialog extends Mixins(StringsMixin, VuetifyMixin, BAIconsMixin) {
    @Prop({ required: true, default: undefined }) value: Array<any>;
    @Prop({ type: String }) title;
    @Prop({ type: Boolean }) small;

    get Title(): string {
        if( this.IsEmpty(this.title) ) return 'Edit Array';
        return this.title;
    }

	isEditing: boolean = false;
	backupValue: Array<any>;
    newArrayItem: string = '';
    get IsEditReady() {
        return this.isEditing;
    }
	onStart() {
        // make a deep copy backup copy of the array
		this.backupValue = [...this.value];
		this.isEditing = true;
	}
	onCancel() {
        // restore categories from backup
        this.value = [...this.backupValue];
        this.backupValue = undefined;
		this.isEditing = false;
        this.$emit("cancel");
	}
	async onAccept() {
        this.$emit("input", this.value);

        // reset state
		this.isEditing = false;
        this.backupValue = undefined;

        this.$emit("accept");
	}

    onAddItem() {
        this.value.push(this.newArrayItem);
        this.newArrayItem = '';
        this.setFocus(this.$refs.newItemTextField);
    }
}

