
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { StringsMixin, VuetifyMixin } from '@/mixins';

@Component
export default class AthleteChip extends Mixins(VuetifyMixin, StringsMixin){

	@Prop({ type: String, default: null }) athleteId: string | null;
	
	@Prop({ type: Boolean, default: false }) hideAvatar: boolean;
	@Prop({ default: 'primary' }) color: string;
	@Prop({ default: undefined }) backgroundColor: string | undefined;
	@Prop({ default: 20 }) avatarSize: number;
	@Prop({ default: 2, type: Number }) avatarMargin: number;
	@Prop({ default: undefined }) borderSize: number | undefined;

	@Prop({ default: false, type: Boolean }) hideName: boolean;
	@Prop({ default: 'small'}) private fontSize: string;

	@Prop({ default: '' }) altText: string;

	get FontClass(): string{
		if( this.fontSize.toUpperCase().startsWith('S') ) return 'user-name-sm';
		if( this.fontSize.toUpperCase().startsWith('M') ) return 'user-name-md';
		if( this.fontSize.toUpperCase().startsWith('L') ) return 'user-name-lg';
		if( this.fontSize.toUpperCase().startsWith('X') ) return 'user-name-xl';
		return 'user-name-md';
	}
}
