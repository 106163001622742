
import { Component, Prop, Vue } from 'vue-property-decorator';
import { subscriptionApi } from "@/api/SubscriptionApi";
import { notificationStore } from '@/store';
import { NotificationModel } from '@/models/notification/NotificationModel';
import { SubscriptionModel } from "@/models/stripe/SubscriptionModel";
import { SubscriptionPlanModel } from '@/models/subscriptionPlan/SubscriptionPlanModel';
import * as ProductKey from '@/../types/constants/product-keys';
import { AthleteSettingsSubscriptionForm } from '@/../types/constants/web_client_user.routes';
import { AvailableFeatures } from '@/../types/interfaces';
import { Route } from 'vue-router';
import { BASIC_PLAN_KEY, COMPLETE_PLAN_KEY, COMPLETE_PLAN_KEY_US, PREMIUM_PLAN_KEY, PREMIUM_PLAN_KEY_US, FREE_PLAN_KEY } from '@/../types/constants/plan-keys';
import { isEmpty } from '@/pipes';

/**
 * This HOC is intended to provide all relevant subscription information (current plan, expiry, etc.) for a given parent id.
 * 
 * @parentId: The ID of the parent domain object you're querying subscription info for.
 */
@Component({
	render(h){
		return h(
			this.as,
			this.$slots.default ? this.$slots.default : this.$scopedSlots.default(this.SlotProps)
		);
	}
})
export default class SubscriptionInfoProvider extends Vue{
	@Prop({ default: 'div' }) as: string;
	@Prop({ required: true }) parentId: string;
	@Prop({ required: true }) resource: string;

	planLoading: boolean = true;
	planInitialized: boolean = false;
	plan: SubscriptionPlanModel | null = null;
	subscription: SubscriptionModel | null = null;
	features: AvailableFeatures | null = null;

	/**
	 * Handing down a route like this allows the link to behave like a native html anchor.
	 * this allows behaviour like middle mouse clicking to open the link in a new tab
	 * but we need to know if it's an impersonation link or not for it to work correctly
	 */
	get GoToPlansRoute(): Partial<Route>{
		return {
			name: AthleteSettingsSubscriptionForm,
			params:{
				...this.$route.params,
			}
		};
	}

	get PlanIsFree(): boolean{
		if(this.plan === null) return false;
		return this.plan.free;
	}

	get PlanIsExpired(): boolean{
		if(this.subscription === null) return false;
		if(this.subscription.currentPeriodEnd === null) return false;
		return this.subscription.currentPeriodEnd.getTime() < new Date().getTime();
	}

	created(): void{
		this.loadPlanInformation();
	}

	async loadPlanInformation(): Promise<void>{
		this.planLoading = true;
		try{
			const {subscription, features} = await subscriptionApi.getSubscriptionAndFeatures(this.resource, this.parentId);
			this.subscription = subscription;
			this.features = features;
			this.plan = isEmpty(this.subscription)? null : this.subscription.plan;

			if(this.subscription !== null) this.planInitialized = true;
			this.planLoading = false;

			this.$emit('loaded', {subscription: this.subscription, features: this.features})
		}catch(e){
			console.error("Failed to load plan information", e);
			notificationStore.pushErrorNotification(new NotificationModel("Unable to load subscription information for this user"));
			this.subscription = null;
			this.features = null;
			this.plan = null;
		}
	}

	get PlanLoading(): boolean {
		return this.planLoading;
	}

	get SlotProps(): Record<string, any>{
		return {
			planLoading: this.PlanLoading,
			planInitialized: this.planInitialized,
			plan: this.plan,
			PlanIsFree: this.PlanIsFree,
			PlanIsExpired: this.PlanIsExpired,
			ComparitiveDataFeatureAvailable: this.ComparitiveDataFeatureAvailable,
			MindsetTestFeatureAvailable: this.MindsetTestFeatureAvailable,
			MindsetReportFeatureAvailable: this.MindsetReportFeatureAvailable,
			SchoolSearchFeatureAvailable: this.SchoolSearchFeatureAvailable,
			VideoMinutesAvailable: this.VideoMinutesAvailable,
			UploadAvailable: this.UploadAvailable,
			ProfileSharesAvailable: this.ProfileSharesAvailable,
			YoutubeUploadsAvailable: this.YoutubeUploadsAvailable,
			VideoClipsAvailable: this.VideoClipsAvailable,
			VideoClipFeatureAvailable: this.VideoClipFeatureAvailable,
			subscription: this.subscription,
			features: this.features,
			loadPlanInformation: () => this.loadPlanInformation(),
			useFeature: (product: string) => this.useFeature(product),
			GoToPlansRoute: this.GoToPlansRoute,
			IsStarterPlan: this.IsStarterPlan,
			IsBasicPlan: this.IsBasicPlan,
			IsCompletePlan: this.IsCompletePlan,
			IsPremiumPlan: this.IsPremiumPlan,
		};
	}

	async useFeature(product: string): Promise<{ code?: string, url?: string }>{
		if(product === ProductKey.VERIFIED_ASSESSMENT){
			const result = await subscriptionApi.redeemVerifiedAssessment(this.resource, this.parentId);
			await this.loadPlanInformation();
			return result;
		}
	}

	private get IsPlanReady(): boolean {
		if( !this.planInitialized ) return false;
		if( !this.features ) return false;
		return true;
	}

	/**
	 * Returns if the Comparitive Data Feature is available
	 */
	get ComparitiveDataFeatureAvailable(): boolean {
		// TODO[epic=payments] Re-enable payment checks for comparative data summary
		// return this.planInitialized && this.features !== null ? 
		// 	this.features.ATHLETE_COMPARITIVE_DATA.total : false;
		return this.planInitialized && true;	
	}

	get MindsetTestFeatureAvailable(): boolean {
		if( !this.IsPlanReady ) return false;
		return this.features.MINDSET_TEST.total
	}

	get MindsetReportFeatureAvailable(): boolean {
		if( !this.IsPlanReady ) return false;
		return this.features.MINDSET_REPORT.total
	}

	/**
	 * Returns if the School Search Feature is available
	 */
	get SchoolSearchFeatureAvailable(): boolean {
		return this.planInitialized && this.features !== null ? 
			this.features.SCHOOL_SEARCH.total : false;
	}

	/**
	 * Returns number of Video Minutes Available
	 */
	get VideoMinutesAvailable(): number {
		if (this.planInitialized && this.features !== null) {
			if (this.features.ATHLETE_VIDEO_MINUTES.total === 0){
				return 0
			}
			const totalMinutes = this.features.ATHLETE_VIDEO_MINUTES.total;
			const usedMinutes = this.features.ATHLETE_VIDEO_MINUTES.used;
			
			return totalMinutes - usedMinutes;
		}
		return 0;
	}
	/**
	 * Returns if Athlete has any Video Minutes Available to them
	 */
	get UploadAvailable(): boolean {
		return this.VideoMinutesAvailable > 0 ? true : false;
	}

	/**
	 * Returns if user has at least 1 video clip available
	 */
	get VideoClipFeatureAvailable(): boolean {
		return this.VideoClipsAvailable === null || this.VideoClipsAvailable > 0;
	}
	

	get ProfileSharesAvailable(): number | null {
		if (this.planInitialized && this.features !== null) {
			if (this.features.PROFILE_SHARE_LIMIT.total === null){
				return null
			} else if (this.features.PROFILE_SHARE_LIMIT.total === 0){
				return 0
			}
			const total = this.features.PROFILE_SHARE_LIMIT.total;
			const used = this.features.PROFILE_SHARE_LIMIT.used;
			return total - used;
		}
		return 0;
	}

	get YoutubeUploadsAvailable(): number {
		if (this.planInitialized && this.features !== null) {
			if (this.features.YOUTUBE_VIDEO_LIMIT.total === 0){
				return 0
			}
			const total = this.features.YOUTUBE_VIDEO_LIMIT.total;
			const used = this.features.YOUTUBE_VIDEO_LIMIT.used;
			return total - used;
		}
		return 0;
	}

	get VideoClipsAvailable(): number | null {
		if (this.planInitialized && this.features !== null) {
			if (this.features.VIDEO_CLIP_LIMIT.total === null){
				return null
			} else if (this.features.VIDEO_CLIP_LIMIT.total === 0){
				return 0
			}
			const total = this.features.VIDEO_CLIP_LIMIT.total;
			const used = this.features.VIDEO_CLIP_LIMIT.used;
			return total - used;
		}
		return 0;
	}

	get IsStarterPlan(): boolean {
		return (this.planInitialized && this.features !== null) ? 
			this.plan.key === FREE_PLAN_KEY : false
	}
	get IsBasicPlan(): boolean {
		return (this.planInitialized && this.features !== null) ? 
			this.plan.key === BASIC_PLAN_KEY : false
	}
	get IsCompletePlan(): boolean {
		return (this.planInitialized && this.features !== null) ? (
			this.plan.key === COMPLETE_PLAN_KEY) || (this.plan.key === COMPLETE_PLAN_KEY_US) : false
	}
	get IsPremiumPlan(): boolean {
		return (this.planInitialized && this.features !== null) ? 
			(this.plan.key === PREMIUM_PLAN_KEY) || (this.plan.key === PREMIUM_PLAN_KEY_US) : false
	}
}
