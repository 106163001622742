import RyzerMindsetImage from "@/assets/img/ryzer/ryzer-mindset.png";
import RyzerEagleImage from "@/assets/img/ryzer/eagle-full.png";
import RyzerEngineerImage from "@/assets/img/ryzer/engineer-full.png";
import RyzerIceImage from "@/assets/img/ryzer/ice-full.png";
import RyzerKnightImage from "@/assets/img/ryzer/knight-full.png";
import RyzerMaverickImage from "@/assets/img/ryzer/maverick-full.png";
import RyzerMusketeerImage from "@/assets/img/ryzer/musketeer-full.png";
import RyzerRocketImage from "@/assets/img/ryzer/rocket-full.png";
import RyzerTrailblazerImage from "@/assets/img/ryzer/trailblazer-full.png";
import { isEmpty } from "@/pipes";

export const RyzerHelp: string = 'https://mindset.ryzer.com/';

export interface RyzerType {
	text: string,
	value: string,
	disabled: boolean,
	color: string,
	image: any,
	url: string,
	characteristics: string[],
	brief: string[],
}

export const RyzerNone: RyzerType = {
	text: "Unknown",
	value: "n/a",
	disabled: false,
	color: "#005AA8",
	image: RyzerMindsetImage,
	url: RyzerHelp,
	// url: "http://athletetypes.ryzer.com",
	characteristics: ['Athlete', '', ''],
	brief: ['ATHLETE', '', ''],
}

export const RyzerEagle: RyzerType = {
	text: "Eagle",
	value: "eagle",
	disabled: false,
	color: "#C79E0D",
	image: RyzerEagleImage,
	url: RyzerHelp,
	// url: "http://athletetypes.ryzer.com/eagle.cfm",
	characteristics: ['Works to please others', 'Optimistic and cooperative', 'Plans ahead'],
	brief: ['ORGANIZED', 'TRADITIONAL', 'COOPERATIVE'],
};

export const RyzerEngineer: RyzerType = {
	text: "Engineer",
	value: "engineer",
	disabled: false,
	color: "#009247",
	image: RyzerEngineerImage,
	url: RyzerHelp,
	// url: "http://athletetypes.ryzer.com/engineer.cfm",
	characteristics: ['Honest and straightforward', 'Carefully thinks through decisions', 'Good at sticking with a routine'],
	brief: ['CEREBRAL', 'HONEST', 'VALUES'],
};

export const RyzerIce: RyzerType = {
	text: "Ice",
	value: "ice",
	disabled: false,
	color: "#7A7B79",
	image: RyzerIceImage,
	url: RyzerHelp,
	// url: "http://athletetypes.ryzer.com/ice.cfm",
	characteristics: ['Sets and focuses on goals', 'Pushes self hard', 'Admits faults'],
	brief: ['COMPETITIVE', 'GRINDER', 'GOAL ORIENTED'],
};

export const RyzerKnight: RyzerType = {
	text: "Knight",
	value: "knight",
	disabled: false,
	color: "#6B297A",
	image: RyzerKnightImage,
	url: RyzerHelp,
	// url: "http://athletetypes.ryzer.com/knight.cfm",
	characteristics: ['Listens before talking', 'Tries to avoid mistakes', 'Respects the coach and mentors'],
	brief: ['RESPECT', 'VALUES', 'LISTENS'],
};

export const RyzerMaverick: RyzerType = {
	text: "Maverick",
	value: "maverick",
	disabled: false,
	color: "#FA6A09",
	image: RyzerMaverickImage,
	url: RyzerHelp,
	// url: "http://athletetypes.ryzer.com/maverick.cfm",
	characteristics: ['Comfortable adapting to the moment', 'Seeks unconventional solutions', 'Willing to take risks'],
	brief: ['INNOVATIVE', 'ADAPTIVE', 'REVELLIOUS'],
};

export const RyzerMusketeer: RyzerType = {
	text: "Musketeer",
	value: "musketeer",
	disabled: false,
	color: "#183257",
	image: RyzerMusketeerImage,
	url: RyzerHelp,
	// url: "http://athletetypes.ryzer.com/musketeer.cfm",
	characteristics: ['Has a strong desire to help others', 'Listens well', 'Considers choices thoughtfully'],
	brief: ['TEAM ORIENTED', 'HELPFUL', 'LOYAL'],
};

export const RyzerRocket: RyzerType = {
	text: "Rocket",
	value: "rocket",
	disabled: false,
	color: "#D70007",
	image: RyzerRocketImage,
	url: RyzerHelp,
	// url: "http://athletetypes.ryzer.com/rocket.cfm",
	characteristics: ['Cool under pressure', 'Gets things done', 'Pushes hard to accomplish goals'],
	brief: ['CONFIDENT', 'CALM', 'BOLD'],
};

export const RyzerTrailblazer: RyzerType = {
	text: "Trailblazer",
	value: "trailblazer",
	disabled: false,
	color: "#3ABBEC",
	image: RyzerTrailblazerImage,
	url: RyzerHelp,
	// url: "http://athletetypes.ryzer.com/trailblazer.cfm",
	characteristics: ['Creative problem solving', 'Empathizes with teammates', 'Comfortable adapting in the moment'],
	brief: ['ENERGIZING', 'EMPATHETIC', 'TRENDSETTING'],
};

export function GetMindsetArchetype(archetype: string): RyzerType {
	if( isEmpty(archetype) ) return RyzerNone;
	if( archetype.toLowerCase().includes('eagle') ) return RyzerEagle;
	else if( archetype.toLowerCase().includes('eng') ) return RyzerEngineer;
	else if( archetype.toLowerCase().includes('ice') ) return RyzerIce;
	else if( archetype.toLowerCase().includes('knight') ) return RyzerKnight;
	else if( archetype.toLowerCase().includes('mav') ) return RyzerMaverick;
	else if( archetype.toLowerCase().includes('musket') ) return RyzerMusketeer;
	else if( archetype.toLowerCase().includes('rocket') ) return RyzerRocket;
	else if( archetype.toLowerCase().includes('trail') ) return RyzerTrailblazer;
	else return RyzerNone;
}

// RyzerApi.ts

export enum MindsetError {
	MindsetNotLoaded = "Mindset report has not been loaded",
	MindsetNotAvailable = "Mindset report is not available",
	NoErrorReported = "General Error",
	RyzerUserDoesNotExist = "This user does not exist in the Mindset system",
	RyzerUserNoTAP = "This user has not completed the TAP",
	RyzerIdMismatch = "User UserID - PartnerID mismatch",
}

export interface RyzerDataModel {
	Error: string;
	html: string;
}

export interface RyzerUserModel {
	UserID: string;
	PartnerID: string;
	FirstName: string;
	LastName: string;
	Gender: string;
	ScoutOptin: string;
	AccountCreatedDTS: string;
	// , "TAP-333238": { "AthleteType": "Knight", "FirstName": "Christian", "LastName": "Monterroso", "Email": "christian.c.monterroso@bestathletes.co", "CodeUsed": "53363315-0cfa-11ed-adf4-1279a54f57e7", "Sport": "", "DateOfBirth": "1974-05-09 00:00:00", "ZipCode": "", "GradYear": "1993", "TestTaken": "2022-07-27 15:27:29" }, "TeamID-3533": "Best Athletes", "TeamID-155": "Ryzer Mindset" }
}

export interface RyzerAthleteDataModel {
	AthleteTypePrimary: string;
	AthleteTypeSecondary: string;
	MentalToughness: string;
	Coachability: string;
	MentalPerformance: string;
	Grit: string;
	GrowthMindset: string;
	SpeedConcentration: string;
	Composure: string;
	AttentionToDetail: string;
	Decisiveness: string;
	Confidence: string;
	RulesFollowing: string;
	Adaptability: string;
	TraitsAboveAvg: string;
	TraitsBelowAvg: string;
	TrafficLight: string;
	EmotionalAdjustment: string;
	LearningAptitude: string;
	Motivation: string;
	Resiliency: string;
	SelfEsteem: string;
	SocialStyle: string;
	MotivatedBy: string;
	CoachingStyle: string;
	DistanceFromHome: string;
	InstructionMethod: string;
	PressurePerformer: string;
	MentalEndurance: string;
	AfterSetbacks: string;
	TAPTaken: string;
	TAPUserAccess: string;
}

export interface RyzerUserInfo extends RyzerUserModel, RyzerDataModel {
	UserID: string;
	PartnerID: string;
	FirstName: string;
	LastName: string;
	Gender: string;
	ScoutOptin: string;
	AccountCreatedDTS: string;
}

export interface RyzerAthleteData extends RyzerAthleteDataModel, RyzerDataModel {
	AthleteTypePrimary: string;
	AthleteTypeSecondary: string;
	MentalToughness: string;
	Coachability: string;
	MentalPerformance: string;
	Grit: string;
	GrowthMindset: string;
	SpeedConcentration: string;
	Composure: string;
	AttentionToDetail: string;
	Decisiveness: string;
	Confidence: string;
	RulesFollowing: string;
	Adaptability: string;
	TraitsAboveAvg: string;
	TraitsBelowAvg: string;
	TrafficLight: string;
	EmotionalAdjustment: string;
	LearningAptitude: string;
	Motivation: string;
	Resiliency: string;
	SelfEsteem: string;
	SocialStyle: string;
	MotivatedBy: string;
	CoachingStyle: string;
	DistanceFromHome: string;
	InstructionMethod: string;
	PressurePerformer: string;
	MentalEndurance: string;
	AfterSetbacks: string;
	TAPTaken: string;
	TAPUserAccess: string;
}
