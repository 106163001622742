import { FrontEndModel } from '@/models/FrontEndModel';
import { AthleteProfileModel } from '@/models';
import { EventLocationModel } from './EventLocationModel';
import { getTime, formatDate, formatDateAndTime } from '@/helpers';
import { baEventApi, eventLocationApi } from '@/api/BAEventsApi';
import { athleteApi } from '@/api/AthleteApi';
import { isEmpty, isNotEmpty, isZero, isEmptyArray } from '@/pipes';
import { AgreementFile } from '@/models/file/FileModel';
import { ObjectCache } from '@/helpers/object-cache';

export enum BAEventStatus {
    draft = "Draft",
    scheduled = "Scheduled",
    inprogress = "In Progress",
    complete = "Complete",
    deleted = "Deleted",
    archived = "Archived",
}

export class EventTicketModel extends FrontEndModel {
    name!: string;
    description?: string;
    price!: number;
    priceUS?: number;
    capacity: number = -1;
    remaining: number = -1;
    stripeId?: string;
    stripeIdUS?: string;
    deleted: boolean = false;

    constructor(id?: string) {
        super();
        this.initializeFromId(id);
    }
    async initializeFromId(id?: string){
        this.id = id?? undefined;
        if( isNotEmpty(id) ) {
            this.SetBlank();
            if( id === "GA" ) this.SetGeneralAdmission();
            if( id === "VIP" ) this.SetVIP();
            if( id === "new" ) {
                const {uuid} = await baEventApi.uuid();
                this.id = uuid;
            }
            this.setRemaining();
        }
    }
    load(obj: Record<string, any>): this {
		Object.assign(this, obj);
        return this;
    }

    assign(ticketType: EventTicketModel) {
        Object.assign(this, ticketType);
        this.id = ticketType.id;
        this.setRemaining();
    }
    setRemaining() {
        if( this.capacity < 0 || this.remaining < 0 || this.remaining > this.capacity ) this.remaining = this.capacity;
    }
    get Description(): string {
        if( isEmpty(this.description) ) return '';
        return this.description;
    }
    get Capacity(): string {
        if( this.deleted ) return 'deleted';
        if( this.capacity < 0 ) return 'unlimited';
        return this.capacity.toString();
    }
    get Remaining(): string {
        if( this.deleted ) return '0';
        if( this.capacity < 0 ) return 'yes';
        return this.remaining.toString();
    }
    get IsFree(): boolean {
        return isZero(this.price);
    }
    get Price(): string {
        if( this.IsFree ) return 'Free';
        return `$ ${Number(this.price).toFixed(2)}`
    }
    get PriceCAD(): string {
        if( this.IsFree ) return 'Free';
        return `CAD$ ${Number(this.price).toFixed(2)}`
    }
    get PriceUS(): string {
        if( isZero(this.priceUS) ) return 'Free';
        return `US$ ${Number(this.priceUS).toFixed(2)}`
    }
    get ToolTip(): string {
        return isEmpty(this.description)? this.name : this.description;
    }

    // set ticket to special type
    private SetBlank() {
        this.id = undefined;
        this.name = '';
        this.description = '';
        this.price = 395;
        this.priceUS = 395;
        this.capacity = -1;
        this.remaining = -1;
        this.deleted = false;
    }
    private SetGeneralAdmission() {
        this.id = undefined;
        this.name = 'General Admission';
        this.description = 'Valid for general entry';
        this.price = 395;
        this.priceUS = 395;
        this.capacity = -1;
    }
    private SetVIP() {
        this.id = undefined;
        this.name = 'VIP';
        this.description = 'Special ticket';
        this.price = 995;
        this.priceUS = 995;
        this.capacity = 5;
    }
}

export class EventParticipantModel extends FrontEndModel {
    attendeeId!: string;
    ticketId!: string;
    redeemed: boolean = false;
    tags: string;
}

export class EventParticipantDetails extends EventParticipantModel{
    athlete: AthleteProfileModel;
    ticket: EventTicketModel;

    get FullName(): string {
        return this.athlete?.FullName;
    }
    get Email(): string {
        return this.athlete?.email;
    }
    get PictureUrl(): string {
        return this.athlete?.pictureUrl;
    }
    get Location(): string {
        return this.athlete?.Location;
    }
    get Ticket(): string {
        if( !this.ticket ) return this.ticketId;
        return this.ticket.name;
    }
}

export class BAEventModel extends FrontEndModel {
    name!: string;
    menu!: string;
    archived: boolean = false;
    description!: string;
    date: Date = new Date();
    location: string = '';                              // location ID
    photo: string = '';
    fileUrl: string = '';
    capacity: number = -1;
    status?: BAEventStatus;
    sponsors: Array<string> = [];                       // sponsor IDs
    participants: Array<EventParticipantModel> = [];    // participants with tickets
    tickets: Array<EventTicketModel> = [];              // ticket types
    stripeId?: string;
    url: string = '';
    meetingUrl?: string;
    showcase: boolean = false;
    showcaseStart: Date;
    showcaseEnd: Date;
    dataLabels: Array<string> = ['First Name', 'Last Name', 'Email', 'Comments'];
    agreements: Array<AgreementFile> = [];
    manualCheckin: boolean = true;
    notes: string = '';

    // ex fields
    locationDetails: EventLocationModel = undefined;

	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		if( obj['date'] ) {
            this.date = new Date( obj['date'] );
        } else {
            this.date = new Date();
        }
        if( obj['tickets'] ) {
            this.tickets = [];
            for( const ticketType of obj['tickets'] ) {
                var ticketObj = new EventTicketModel(ticketType.id);
                ticketObj.assign(ticketType);
                this.tickets.push(ticketObj);
            }
        }
        if( obj['showcaseStart'] ) {
            this.showcaseStart = new Date( obj['showcaseStart'] );
        } else {
            this.showcaseStart = new Date();
            this.showcaseStart.setDate(this.date.getDate() - 14);
        }
        this.showcaseEnd = new Date();
        this.showcaseEnd.setDate(this.date.getDate() + 5);
        if( obj['agreements']) {
            this.agreements = [];
            for( const agreement of obj['agreements'] ) {
                var agreementObj = new AgreementFile(agreement.title, agreement.fileUrl);
                this.agreements.push(agreementObj);
            }
        }
        
		return this;
	}
    async loadEx(locationsCache: ObjectCache<EventLocationModel> = undefined) {
        await this.getLocation(locationsCache);
    }

    // locations: ObjectCache<EventLocationModel> = new ObjectCache<EventLocationModel>();
    get HasLocation(): boolean {
        return( isNotEmpty(this.location) );
    }
    async getLocation(locationsCache: ObjectCache<EventLocationModel>) : Promise<EventLocationModel> {
        if( !this.HasLocation ) return undefined;

        if( isNotEmpty(locationsCache) ) {
            this.locationDetails = locationsCache.get(this.location);
            if( isNotEmpty(this.locationDetails) ) return this.locationDetails;
        }

        this.locationDetails = await eventLocationApi.findById(this.location);

        if( isNotEmpty(locationsCache) ) {
            locationsCache.set(this.locationDetails);
        }
        return this.locationDetails;
    }
    get Online(): boolean {
        if( !this.Location ) return false;
        return this.Location.online;
    }
    get Location(): EventLocationModel {
        return this.locationDetails;
    }
    get LocationName(): string {
        if( !this.Location ) return '';
        return this.locationDetails.name;
    }
    get LocationAddress(): string {
        if( !this.Location ) return '';
        return this.locationDetails.address;
    }
    get Locale(): string {
        if( !this.Location ) return '';
        return this.locationDetails.Location;
    }
    get LocationMap(): string {
		if( !this.Location ) return `https://maps.google.com/`;
        return this.locationDetails.GoogleMap();
    }
    get HasWebsite(): boolean {
        return( !!this.url && this.url.length > 0 );
    }
    get Website(): string {
        if( !this.HasWebsite ) return '';
        return this.url;
    }
    get IsToday(): boolean {
        if( !this.date ) return false;
        const today = new Date();
    
        return (
            this.date.getFullYear() === today.getFullYear() &&
            this.date.getMonth() === today.getMonth() &&
            this.date.getDate() === today.getDate()
        );
    }
    get DateStr(): string {
        if( !this.date ) return new Date().toDateString();
        return this.date.toDateString();
    }
    get TimeStr(): string {
        if( !this.date ) return '';
        return getTime(this.date);
    }
    get DateFormatted(): string {
        if( !this.date ) return '';
        return formatDate(this.date);
    }
    get ShowcaseStartDateAndTime(): string {
        if( !this.showcaseEnd ) return '';
        return formatDateAndTime(this.showcaseStart);
    }
    get ShowcaseEndDateAndTime(): string {
        if( !this.showcaseEnd ) return '';
        return formatDateAndTime(this.showcaseEnd);
    }

    get Capacity(): string {
        if( this.capacity < 0 ) return 'unlimited';
        return this.capacity.toString();
    }

    Ticket(ticketId: string ): EventTicketModel {
        if( !this.HasTickets ) return undefined;
        return this.tickets.find(ticket => ticket.id == ticketId);
    }
    get OneTicket(): EventTicketModel {
        if( !this.HasOneTicket ) return undefined;
        return this.tickets[0];
    }
    get HasTickets(): boolean {
        if( !this.tickets ) return false;
        return ( this.tickets.length > 0 );
    }
    get HasOneTicket(): boolean {
        if( !this.tickets ) return false;
        return ( this.tickets.length === 1 );
    }
    get HasParticipants(): boolean {
        if( isEmptyArray(this.participants) ) return false;
        return ( this.participants.length > 0 );
    }
    get Tickets(): EventTicketModel[] {
        return this.tickets.filter(t => !t.deleted );
    }

    AthleteTicket(athleteId: string): EventTicketModel {
        const attendee = this.participants.find(a => a.attendeeId === athleteId)
        if( !attendee ) return undefined;

        return this.tickets.find(t => t.id == attendee.ticketId);
    }

    isAttending(attendeeId: string): boolean {
        if( !this.HasParticipants ) return false;

        return this.participants.some(a => a.attendeeId === attendeeId);
    }
    async addAttendee(athlete: AthleteProfileModel, ticket: EventTicketModel): Promise<boolean> {
        // if athlete is already attending, do not add again
        if( this.isAttending(athlete.id) ) return false;

        // add attendee to event
        const attendee = new EventParticipantModel;
        attendee.attendeeId = athlete.id;
        attendee.ticketId = ticket.id;
        this.participants.push(attendee);

        // add event to attendee
        var athlete: AthleteProfileModel = await athleteApi.findById(attendee.attendeeId);
        if( !!athlete && athlete.addEvent(this) ) {
            await athleteApi.save(athlete)
        }
        return true;
    }
    async deleteAttendee(attendeeDetails: EventParticipantModel): Promise<boolean> {
        var index = -1;
        if( attendeeDetails.attendeeId && attendeeDetails.ticketId ) {
            index = this.participants.findIndex(item => ( item.attendeeId == attendeeDetails.attendeeId ) && ( item.ticketId == attendeeDetails.ticketId ));
        } else if( attendeeDetails.attendeeId ) {
            index = this.participants.findIndex(item => ( item.attendeeId == attendeeDetails.attendeeId ) && ( !attendeeDetails.ticketId ));
        } else if( attendeeDetails.ticketId ) {
            index = this.participants.findIndex(item => ( !attendeeDetails.attendeeId ) && ( item.ticketId == attendeeDetails.ticketId ));
        }
        if( index < 0 ) return false;

        // remove attendee from event
        this.participants.splice(index, 1);

        // remove event from attendee
        var athlete: AthleteProfileModel = await athleteApi.findById(attendeeDetails.attendeeId);
        if( !!athlete && athlete.removeEvent(this.id) ) {
            await athleteApi.save(athlete);
        }
        return true;
    }
    async deleteAllParticipants(): Promise<boolean> {
        while( this.HasParticipants ) {
            const last = this.participants[this.participants.length - 1];
            if( !this.deleteAttendee(last) ) return false;
        }
        return true;
    }

    HasFile(): boolean {
        return isNotEmpty(this.fileUrl);
    }

    get ShareLink(): string {
        return `baevent/${this.id}`;
    }
}