// StringsMixin.ts

import { Component, Vue } from 'vue-property-decorator';
import { AnalyticsGender } from '@/../../libs/ba-types/src';
import { Gender } from '@/../types/enums';
import { formatDatePrettyLong, formatDateHyphensYYYYMMDD, formatDateSlashesYYYYMMDD, formatDatePretty, formatDatePrettyShort, getTime, formatDateEval, formatDateAndTime, formatDate, formatDateLastModified } from '@/helpers/date';
import { snipEmail, snipAthleteId, linkify, isEmpty, isNotEmpty, isEmptyArray, isNotEmptyArray, title, fullName } from '@/pipes';
import { genderText, genderTextPlural, genderShort } from '@/pipes';
import { BaseSoccerPosition, DominantSide, Length, Mass } from '@/../types/enums';
import { formatPositionShort, formatDominantShort } from "@/helpers/strings"
import convert from "convert-units";
import { capitalize, lowercase, uppercase, role } from '@/pipes';
import { capitalizeCamelCase } from '@/pipes/capitalizeCamelCase.pipe'
import { genderType } from '@/pipes';

@Component
export class StringsMixin extends Vue {
	formatDateStd = formatDate;
	formatDate = formatDatePrettyLong;
	formatDatePretty = formatDatePretty;
	formatDatePrettyShort = formatDatePrettyShort;
	formatDatePrettyLong = formatDatePrettyLong;
	formatDateSlashesYYYYMMDD = formatDateSlashesYYYYMMDD;
	formatDateHyphens = formatDateHyphensYYYYMMDD;
	formatDateEval = formatDateEval;
	formatDateAndTime = formatDateAndTime;
	formatDateLastModified = formatDateLastModified;
	getTime = getTime;
	convert = convert;
	capitalize = capitalize;
	lowercase = lowercase;
	uppercase = uppercase;
	capitalizeCamelCase = capitalizeCamelCase;
	roleToStr = role;
	titleCase = title;
	formatPositionShort = formatPositionShort;
	genderType = genderType;

	IsEmpty(data: any): boolean {
		return isEmpty(data);
	}
	IsNotEmpty(data: any): boolean {
		return isNotEmpty(data);
	}
	IsEmptyArray(a: Array<any>): boolean {
		return isEmptyArray(a);
	}
	IsNotEmptyArray(a: Array<any>): boolean {
		return isNotEmptyArray(a);
	}
	SetIfEmpty(dest: any, value: string): boolean {
		if( isNotEmpty(dest) ) return false;
		dest = value;
		return true;
	}
	NormalizeString(input: string): string {
		return input.replace(/\s+/g, '').toLowerCase();
	}	
	Snip(s?: string, max: number = 16, tail: boolean = true): string {
		if( !s ) return '';
		if( s.length <= max ) return s;
		return s.substring(0, max-(tail? 4:3)) + '...' + (tail? s.charAt(s.length-1) : '');
	}
	SnipUrl(url: string, len: number = -1): string {
		if( this.IsEmpty(url) ) return '';
		url = this.TrimUrl(url);
		const idxDot = url.lastIndexOf('.');
		const idxEnd = url.length;
		const idx = (idxEnd - idxDot) > 8? (idxEnd - 8) : idxDot;
		if( len < 0 ) {
			const doubleSlash = url.indexOf('//');
			var slash = url.indexOf('/', doubleSlash + 2);
			if( slash < 7 ) slash = idxEnd;
			len = slash;
		}
		if( len >= idx ) return url;
		return url.substring(0,len) + '...' + url.substring(idx,idxEnd);
	}
	SnipEmail(email: string, maxUsername: number = 9, maxDomain: number = 15): string {
		return snipEmail(email, maxUsername, maxDomain);
	}
	SnipAthleteId(athleteId: string, len: number = 8): string {
		return snipAthleteId(athleteId, len);
	}
	FullName(given_name: string, family_name: string): string {
		return fullName(given_name, family_name);
	}

	TrimUrl(url: string): string {
		if( this.IsEmpty(url) ) return '';
		if( url.charAt(url.length - 1) === '/' ) url = url.substring(0, url.length - 1);
		const tripleW = url.indexOf('www.');
		if( tripleW >= 0 ) return url.substring(tripleW+4);
		const doubleSlash = url.indexOf('//');
		if( doubleSlash >= 0 ) return url.substring(doubleSlash+2);
		return url;
	}

	GenderText(gender: Gender | AnalyticsGender): string {
		return genderText(gender);
	}
	GenderTextPlural(gender: Gender | AnalyticsGender, isAdult: boolean = false): string {
		return genderTextPlural(gender, {adult: isAdult});
	}
	GenderShort(gender: Gender | AnalyticsGender): string {
		return genderShort(gender);
	}

	PositionShort(pos: BaseSoccerPosition): string {
		return formatPositionShort(pos);
	}

	DominantSideShort(side: DominantSide): string {
		return formatDominantShort(side);
	}

	HeightInFt(height: number, heightUnit: Length = Length.CENTIMETER): string {
		if( this.IsEmpty(height) ) return '';
		return `${~~convert(height).from(heightUnit).to("ft")}' ${~~convert(height).from(heightUnit).to("in") % 12}"`;
	}
	HeightInCm(height: number, heightUnit: Length = Length.CENTIMETER): string {
		if( this.IsEmpty(height) ) return '';
		return `${~~convert(height).from(heightUnit).to("cm").toFixed(0)} cm`;
	}
	WeightInLbs(weight: number, weightUnit: Mass = Mass.KILOGRAM): string {
		if( this.IsEmpty(weight) ) return '';
		return `${~~convert(weight).from(weightUnit).to("lb").toFixed(0)} lbs`;
	}
	WeightInKg(weight: number, weightUnit: Mass = Mass.KILOGRAM): string {
		if( this.IsEmpty(weight) ) return '';
		return `${~~convert(weight).from(weightUnit).to("kg").toFixed(0)} kg`;
	}

	HeightStr(height: number, heightUnit: Length = Length.CENTIMETER): string {
		if( !height ) return '';
		if( height <= 0 ) return '';

		return `${ ~~convert(height).from(heightUnit).to("ft") }' ${ ~~convert(height).from(heightUnit).to("in") % 12 }” / ${ ~~convert(height).from(heightUnit).to("cm").toFixed(0) } cm`
	}
	WeightStr(weight: number, weightUnit: Mass = Mass.KILOGRAM): string {
		if( !weight ) return '';
		if( weight <= 0 ) return '';

		return `${~~convert(weight).from(weightUnit).to("lb")} lbs / ${ ~~convert(weight).from(weightUnit).to("kg").toFixed(0) } kg`;
	}

	KeyRepresents(key: string, label: string ): boolean {
		return this.NormalizeString(key).includes(this.NormalizeString(label));
	}

	linkify(link: string): string {
		return linkify(link);
	}

	formatRecord(item: Record<string,any>): any {
		if( typeof item.value === 'string' ) return item.value;
		else if( typeof item.value === 'number' ) return item.value;
		else if( Array.isArray(item.value) ) return item.value.join(' & ');
		else if( item.value instanceof Date ) return this.formatDatePrettyShort(item.value, true);
		else return item.value;
	}
}
