import { render, staticRenderFns } from "./JerseyEditColor.vue?vue&type=template&id=33ce3594&"
import script from "./JerseyEditColor.vue?vue&type=script&lang=ts&"
export * from "./JerseyEditColor.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports