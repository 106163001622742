
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BAIconsMixin, StringsMixin, VuetifyMixin } from '@/mixins';
import { AthleteReportCategory, AthleteReportCategoryHeading } from '@/models';
import { cloneDeep } from "lodash";

@Component({
    components: {}
})
export default class AssessmentReportEditHeadingDialog extends Mixins(StringsMixin, VuetifyMixin, BAIconsMixin) {
    @Prop({ required: true, default: undefined }) value: AthleteReportCategoryHeading;
    @Prop({ type: Boolean }) small;

    async mounted() {
    }

	editingHeading: boolean = false;
	backupValue: AthleteReportCategoryHeading;
    newAthleteReportHeadingCategory: string = '';
    get IsEditReady() {
        return this.editingHeading;
    }
	onStartEditHeading() {
        // make a deep copy backup copy of the heading
		this.backupValue = cloneDeep(this.value);
		this.editingHeading = true;
	}
	onCancelEditHeading() {
        // restore categories from backup
        this.value.categories = cloneDeep(this.backupValue.categories);
        this.backupValue = undefined;
		this.editingHeading = false;
        this.$emit("cancel");
	}
	async onAcceptEditHeading() {
        this.$emit("input", this.value);

        // reset state
		this.editingHeading = false;
        this.backupValue = undefined;

        this.$emit("accept");
	}

    onAddAthleteReportHeadingCategory() {
        const category: AthleteReportCategory = new AthleteReportCategory;
        category.name = this.newAthleteReportHeadingCategory;
        this.newAthleteReportHeadingCategory = '';
        this.setFocus(this.$refs.athletereportHeadingCategory);
        this.value.categories.push(category);
    }
}

