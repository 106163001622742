
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin, StringsMixin } from '@/mixins';
import { notificationStore } from '@/store';

@Component
export default class CopyToClipboard extends Mixins(VuetifyMixin, StringsMixin) {
	@Prop({ default: 'primary' }) hoverColor: string;
	@Prop() text: string;
	@Prop() clipboardText: string;
	@Prop() tooltipText: string;
	@Prop() confirmText: string;
	@Prop({ type: Boolean }) noIcon: boolean;
	@Prop({ type: Boolean }) button: boolean;
	@Prop({ type: Boolean }) padded: boolean;
	@Prop({ type: Boolean }) dark: boolean;
	@Prop({ type: String, default: 'primary'}) iconColor: string;
	@Prop({ type: String, default: 'mdi-content-copy' }) icon: string;
	@Prop({ type: Boolean }) smallIcon;
	@Prop({ type: Boolean }) smallText;
	@Prop({ type: Boolean }) fixedWidth;
	@Prop({ type: Boolean }) link;

	$refs:{
		animatedText: HTMLSpanElement;
		copyToClipboardEl: HTMLSpanElement;
	}

	get TextToDisplay(): string {
		if( this.IsNotEmpty( this.text ) ) return this.text;
		return this.Snip(this.ClipboardText, 40);
	}
	
	get ClipboardText(): string {
		if( this.IsNotEmpty( this.clipboardText ) ) return this.clipboardText;
		return this.text;
	}
	get ToolTipText(): string {
		if( this.IsNotEmpty( this.tooltipText ) ) return this.tooltipText;
		return `Copy ${this.Snip(this.ClipboardText, 15)} to clipboard`;
	}
	get ConfirmationText(): string {
		if( this.IsNotEmpty( this.confirmText ) ) return this.confirmText;
		return `Copied ${this.Snip(this.ClipboardText, 25, false)}`;
	}
	animationText = '';
	runClickAnimation(){
		this.$refs.animatedText.classList.remove('run-animation');
		this.$refs.animatedText.offsetWidth;
		this.$refs.animatedText.classList.add('run-animation');
	}
	copyToClipboard(){
		if(this.button === true){
			this.$emit('click');
		}
		this.animationText = this.ClipboardText;
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = this.ClipboardText;
		this.$refs.copyToClipboardEl.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		this.$refs.copyToClipboardEl.removeChild(selBox);
		this.runClickAnimation();
		notificationStore.pushSnackbarSuccess({message: this.ConfirmationText});
		this.animationText = '';
	}

	get TextClass(): string {
		let textClass: string = "display-text text-no-wrap text-truncate primary--text";
		if( this.fixedWidth ) textClass += " font-monospace";
		else if( this.smallText ) textClass += " text-caption";
		return textClass;
	}
}
