// This plugin is slightly different from the one defined in webAuth.js.  This one is used as part of 
// the token validation process.  The one defined in webAuth.js is used for authentication, signup and 
// password reset process.
import Vue from 'vue'
import createAuth0Client from '@auth0/auth0-spa-js'
import { userApi } from '@/api/UserApi'
import { userStore } from '@/store'

// Define a default action to perform after authentication
const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname)

let instance

// Returns the current instance of the SDK
export const getInstance = () => instance
/* eslint-disable */

// Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance
export const useAuth0 = ({
                           onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
                           redirectUri = window.location.origin,
                           ...options
                         }) => {
  if (instance) return instance

  // The 'instance' is simply a Vue object
  instance = new Vue({
    computed:{
      loading:{
        set(loading) { return userStore.setLoading(loading); },
        get(){ return userStore.loading; },
      },
      isAuthenticated:{
        set(isAuthenticated) { return userStore.setIsAuthenticated(isAuthenticated); },
        get(){ return userStore.isAuthenticated; },
      },
      user:{
        set(user) { return userStore.setUser(user); },
        get() { return userStore.user; },
      },
      auth0User: {
        get() { return userStore.auth0User; },
        set(user) { return userStore.setAuth0User(user); },
      },
      auth0Client:{
        get() { return userStore.auth0Client; },
        set(auth0Client) { userStore.setAuth0Client(auth0Client); }
      },
      popupOpen(){ return userStore.popupOpen; },
      error(){ return userStore.error; },
      token(){ return userStore.token; },
      IsReady() { return userStore.IsReady; },
      FullName() { return userStore.FullName; },
      authId() { return userStore.authId; },
      athleteId() { return userStore.athleteId; },
      athlete() { return userStore.athlete; },
      coachId() { return userStore.coachId; },
      completedProfile() { return userStore.completedProfile; },
      HasCoachProfile() { return userStore.HasCoachProfile; },
      HasAthleteProfile() { return userStore.HasAthleteProfile; },
      IsInstructorAdmin() { return userStore.IsInstructorAdmin; }
    },
    methods: {
      loginWithRedirect(o){
        return userStore.loginWithRedirect(o);
      },
      async refreshToken(ignoreCache = false) {
        return userStore.refreshToken(ignoreCache);
      },
      getTokenSilently(o) {
        return userStore.getTokenSilently(o);
      },
      logout() {
        const bc = new BroadcastChannel('auth-channel');
        bc.postMessage({ type: 'logout' }); // Broadcast logout event
        userStore.logout();
      },
      setAthleteProfile(athlete) {
        return userStore.setAthleteProfile(athlete);
      },
      setCoachProfile(coach) {
        return userStore.setCoachProfile(coach);
      },
      async addUserRoles(roles) {
        return userStore.addUserRoles(roles);
      },
      async setUserRoles(roles) {
        return userStore.setUserRoles(roles);
      },
      useAthleteProfile(opts){
        return userStore.useAthleteProfile(opts);
      }
    },
    // Use this lifecycle method to instantiate the SDK client
    async created() {
      // Create a new instance of the SDK client using members of the given options object
      console.log("Creating Auth0 Client");
      this.auth0Client = await createAuth0Client({
        domain: options.domain,
        client_id: options.clientId,
        audience: options.audience,
        redirect_uri: redirectUri,
        cacheLocation: 'localstorage',
      });

      try {
        // If the user is returning to the app after authentication..
        if (
          window.location.search.includes('code=') &&
          window.location.search.includes('state=')
        ) {
          // handle the redirect and retrieve tokens
          const { appState } = await this.auth0Client.handleRedirectCallback()
          console.log("Auth0Plug handle redirect", JSON.stringify({ appState }));

          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)
          onRedirectCallback(appState)
        }
      } catch (e) {
        console.error('Authentication Error', e)
        this.error = e;
        this.loginWithRedirect({ appState: { targetUrl: '/' } });
      } finally {
        // Initialize our internal authentication state
        this.isAuthenticated = await this.auth0Client.isAuthenticated()
        try{
          this.auth0User = await this.auth0Client.getUser()
        }catch(e){
          console.error("Failed to set user", e);
        }
        
        // did we get a user token from Auth0?
        if (this.auth0User) {
          await this.refreshToken();
          this.user = await userApi.findByAuthId(this.auth0User.sub);
        }

        this.loading = false;
      }
    }
  })

  return instance
}

export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options);
  }
}
