
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '@/mixins/VuetifyMixin';
import { AthleteReportCategory } from '@/models'

@Component
export default class ScoreChip extends Mixins(VuetifyMixin){
	@Prop() category: AthleteReportCategory;
	@Prop({default: 0}) precision: number;
	@Prop({default: 1}) tooltipPrecision: number;

	get CategoryRating(): string {
		if( !this.category ) return '--';
		if( !this.category.rating ) return '--';
		if( this.category.rating > 4.9 ) return '4+'
		return this.category.rating.toFixed(this.precision);
	}

	get CategoryRatingTooltip(): string {
		if( !this.category ) return '--';
		if( !this.category.rating ) return '--';
		if( this.category.rating > 4.9 ) return '4+'
		return this.category.rating.toFixed(this.tooltipPrecision);
	}

	get ToolTipColor(): string {
		if( !this.category ) return 'white';
		if( !this.category.rating ) return 'error';
		if( this.category.rating <= 0 ) return 'error';

		return this.CategoryColor;
	}

	get CategoryColor(): string {
		if( !this.category ) return 'primary--text';
		if( !this.category.rating ) return 'primary--text';

		else if( this.category.rating >= 4.5 ) return 'baColorInternational';
		else if( this.category.rating >= 3.5 ) return 'baColorProfessional';
		else if( this.category.rating >= 2.5 ) return 'baColorHighPerformance';
		else if( this.category.rating >= 1.5 ) return 'baColorCompetitive';
		else if( this.category.rating > 0 ) return 'baColorRecreational';

		return 'primary--text';
	}

	get ToolTip(): string {
		if( !this.category ) return 'Rating not available';
		if( !this.category.rating ) return 'Rating not available';

		return `${this.category.name}: ${this.CategoryRatingTooltip}`
	}
}
