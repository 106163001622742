import { CrudApi } from './CrudApi';
import { AthleteProfileModel } from '../models/athlete/AthleteProfileModel';
import { PaginatedResponse, PublicProfileInfo, QueryOptions, RecruitingProfileGetParam, RepositoryQuery } from '@/../types/interfaces';
import { RecruitingProfileModel } from '@/models/athlete/RecruitingProfileModel';
import { CustomerApi } from "./CustomerApi";
import { ViewTrackingRecordViewModel } from '@/models/viewTrackingRecord/viewTrackingRecordViewModel';
import { AxiosRequestConfig } from 'axios';
import { AthleteAssessmentDataModel } from '@/models';
import { AthleteAutocompleteInfo, CompareDto, GetSpiderChartApiResponse, AthleteScoutingReportDetails } from '@best-athletes/ba-types';
import { ContactInfo } from '@/../types/interfaces/ContactInfo';
import { DefaultVisibilitySetting } from "@/models/athlete/AthleteProfileModel";
import { sportStore } from '@/store';
import { isEmpty, isEmptyArray, isNotEmpty } from '@/pipes';

class AthleteApi extends CrudApi<AthleteProfileModel>{
	constructor() {
		super('athlete', (obj) => new AthleteProfileModel(obj));
	}

	async UpdateSports(athleteProfile: Partial<AthleteProfileModel>, save: boolean = false) {
		try {
			if( !sportStore.loadedSports ) {
				console.warn(`Sports collection not loaded before athlete profile`);
				await sportStore.loadSports();
			};

			// support for legacy 'athleteProfile.sports'
			// convert 'athleteProfile.sports' into 'athleteProfile.athleteSports' and 'athleteProfile.activeSport'
			if( isEmpty(athleteProfile?.athleteSports) ) {
					athleteProfile.athleteSports = [];

					if( isNotEmpty(athleteProfile?.sports) ) {
						for( const sport of athleteProfile.sports ) {
							const athleteSport = sportStore.sports.find( s => s.name === sport.id )
							if( athleteSport ) {
								athleteProfile.athleteSports.push( athleteSport.id );
								athleteProfile.activeSport = athleteSport.id;
							}
						}
					}

					if( save ) this.patch(athleteProfile);
			}

			// populate 'athleteProfile.participantSports' from 'athleteProfile.athleteSports'
			athleteProfile.participantSports = athleteProfile.athleteSports.map(sportId => {
				return sportStore.sports.find( s => s.id === sportId )
			})

			if( !athleteProfile.activeSport ) athleteProfile.activeSport = athleteProfile.athleteSports[0];
		} catch(e) {
			console.error(`Error Updating Sports`, e)
		}
	}

	private async prepareAthleteProfile(obj: Partial<AthleteProfileModel>): Promise<AthleteProfileModel | null> {
		if( isEmpty(obj) ) return null;
		try {
			await this.UpdateSports(obj);
			var athleteProfile: AthleteProfileModel = new AthleteProfileModel(obj);
			await athleteProfile.loadTeams();
			return athleteProfile;
		} catch(e) {
			console.error(`prepareAthleteProfile: Error preparing athlete: ${obj.id}: ${e}`);
			return null;
		}
	}

	async findById(id: string, options?: QueryOptions, config?: AxiosRequestConfig): Promise<AthleteProfileModel | null>{
		const obj = await CrudApi.Api((c) => c.get(`/${this.resource}/${id}`, this.applyOptionsToRequest(options, config)));
		return await this.prepareAthleteProfile(obj);
	}

	async publicFindById(id: string, options?: QueryOptions, config?: AxiosRequestConfig): Promise<AthleteProfileModel | null>{
		const obj = await CrudApi.Api(c => c.get(`/${this.resource}/public/${id}`, this.applyOptionsToRequest(options, config)));
		return await this.prepareAthleteProfile(obj);
	}

	async findByEmail(email: string): Promise<AthleteProfileModel | null> {
		// check for valid email address
		if( !email.toLowerCase().match(/^\S+@\S+\.\S+$/) ) return null;

		const query: RepositoryQuery<AthleteProfileModel> = { 
			search: email.trim().replace(/[\+]/g, '\\+'),
			fields: ['email']
		};
		const options: QueryOptions = {};
		const athleteFound = await this.queryAll(query, options);
		if( athleteFound.count === 0 ) return null;
		
		return await this.prepareAthleteProfile(athleteFound.docs[0]);
	}

	async findByName(firstName: string, lastName: string): Promise<AthleteProfileModel | null> {
		const query: RepositoryQuery<AthleteProfileModel> = { 
			search: lastName.trim(),
			fields: ['lastName']
		};
		const options: QueryOptions = {};
		const athletesFound = await this.queryAll(query, options);
		if( athletesFound.count == 0 ) return null;
		const obj = athletesFound.docs.find(a => a.firstName === firstName);
		return await this.prepareAthleteProfile(obj);
	}

	async findByContact(contact: ContactInfo): Promise<AthleteProfileModel | null> {
		var athlete = await this.findByEmail(contact.email);
		if( !athlete ) athlete = await this.findByName(contact.firstName, contact.lastName);
		
		return athlete;
	}

	async createAthleteProfile(athleteProfile: Partial<AthleteProfileModel>, config?: AxiosRequestConfig ): Promise<AthleteProfileModel> {
		if( isEmpty(athleteProfile) ) return undefined;

		const obj = await CrudApi.Api((c) => c.post(`/${this.resource}/create`, athleteProfile, config));
		return await this.prepareAthleteProfile(obj);
	}
	async getPublicProfile(id: string): Promise<AthleteProfileModel> {
		const obj = await CrudApi.Api(c => c.get(`/${this.resource}/public/${id}`));
		const athlete: AthleteProfileModel = await this.prepareAthleteProfile(obj);
		return athlete;
	}
	
	async getFullProfile(id: string): Promise<AthleteProfileModel> {
		const obj = await CrudApi.Api(c => c.get(`/${this.resource}/public/${id}/profile`));
		return await this.prepareAthleteProfile(obj);
	}
	
	async getBasicProfile(id: string): Promise<PublicProfileInfo<AthleteProfileModel>> {
		const obj = await CrudApi.Api(c => c.get(`/${this.resource}/public/${id}/profile-basic`));
		return await this.prepareAthleteProfile(obj);
	}
	
	async getAvailableProfilesForUser(): Promise<AthleteProfileModel[]> {
		const result = await CrudApi.Api(c => c.get(`/${this.resource}/available-profiles`));
		return result.map(a => this.prepareAthleteProfile(a));
	}
	async getProfilePicture(id: string): Promise<PublicProfileInfo<AthleteProfileModel>> {
		const obj = await CrudApi.Api(c => c.get(`/${this.resource}/public/${id}/profile-picture`));
		return obj;
	}
	async getScoutingReportDetails(id: string): Promise<AthleteScoutingReportDetails> {
		const details = await CrudApi.Api(c => c.get(`/${this.resource}/${id}/scouting-report-details`));
		return {
			...details,
			dateOfBirth: details.dateOfBirth ? new Date(details.dateOfBirth) : undefined,
		};
	}

	async getAthleteRecruitingProfile(id: string, options: RecruitingProfileGetParam): Promise<RecruitingProfileModel> {
		const result = await CrudApi.Api((c) => c.get(`/${this.resource}/recruiting-profile/${id}`, {params: options}));
		return new RecruitingProfileModel().load(result);
	}

	async getSharingProfile(id: string, sharingUrlId: string): Promise<RecruitingProfileModel> {
		/**
		 * Same as recruiting profile except the url
		 */
		const result = await CrudApi.Api((c) => c.get(`/${this.resource}/${id}/sharing/${sharingUrlId}`));
		return new RecruitingProfileModel().load(result);
	}

	async athleteAutocomplete(search: string, options: QueryOptions, config?: AxiosRequestConfig): Promise<PaginatedResponse<AthleteAutocompleteInfo>> {
		const response: PaginatedResponse<AthleteAutocompleteInfo> = await CrudApi.Api(
			(c) => c.post(`/${this.resource}/autocomplete`, { search }, this.applyOptionsToRequest(options, config))
		);
		return {
			total: response.total,
			count: response.count,
			page: response.page,
			totalPages: response.totalPages,
			docs: response.docs,
		};
	}

	async updateViewRecord(viewRecord: ViewTrackingRecordViewModel, config?: AxiosRequestConfig): Promise<ViewTrackingRecordViewModel> {
		const newObj = await CrudApi.Api((c) => c.put(`/${this.resource}/${viewRecord.parentId}/viewRecords/${viewRecord.id}`, viewRecord, config));
		return new ViewTrackingRecordViewModel().load(newObj);
	}
	
	async queryViewRecords(id: string, query: RepositoryQuery<ViewTrackingRecordViewModel>, options: QueryOptions, config?: AxiosRequestConfig): Promise<PaginatedResponse<ViewTrackingRecordViewModel>> {
		const response: PaginatedResponse<ViewTrackingRecordViewModel> = await CrudApi.Api(
			(c) => c.post(`/${this.resource}/${id}/viewRecords/query`, query, this.applyOptionsToRequest(options, config))
		);
		return {
			total: response.total,
			count: response.count,
			page: response.page,
			totalPages: response.totalPages,
			docs: response.docs.map((doc: any) => new ViewTrackingRecordViewModel().load(doc)),
		};
	}

	async queryAssessmentsByAthleteId(athleteId: string, query: RepositoryQuery<AthleteAssessmentDataModel>, options: QueryOptions, config?: AxiosRequestConfig): Promise<PaginatedResponse<AthleteAssessmentDataModel>> {
		const response: PaginatedResponse<AthleteAssessmentDataModel> = await CrudApi.Api(
			(c) => c.post(`/${this.resource}/${athleteId}/assessments/query`, query, this.applyOptionsToRequest(options, config))
		);
		return {
			total: response.total,
			count: response.count,
			page: response.page,
			totalPages: response.totalPages,
			docs: response.docs.map((doc: any) => new AthleteAssessmentDataModel().load(doc)),
		};
	}

	async createSetupIntent(id: string): Promise<{client_secret: string} | null> {
		return CustomerApi.createSetupIntent('athlete', id)
	}

	async getAssessmentRadarChart(athleteId: string, body: CompareDto, config?: AxiosRequestConfig): Promise<GetSpiderChartApiResponse> {
		return await CrudApi.Api(c => c.post(`/${this.resource}/${athleteId}/assessments/compare`, body, config));
	}

	async setVisibilityToAll(config?: AxiosRequestConfig): Promise<number> {
		const response = await CrudApi.Api(c => c.post(`/${this.resource}/visibility`, config));
		return response.number;
	}
	async setVisibility(athleteId: string, visibility = DefaultVisibilitySetting): Promise<AthleteProfileModel> {
		const config: AxiosRequestConfig = {
			params: {
				visibility: visibility,
			}
		}
		const obj = await CrudApi.Api(c => c.post(`/${this.resource}/visibility/${athleteId}`, config));
		return await this.prepareAthleteProfile(obj);
	}
	
	private async processDuplicates(duplicates: Array<AthleteProfileModel>): Promise<Array<AthleteProfileModel>> {
		if( isEmptyArray(duplicates) ) return [];

		let processedDups: Array<AthleteProfileModel> = [];
		for(const dup of duplicates) {
			let athleteProfile = await this.prepareAthleteProfile(dup);
			const recruitingProfile: RecruitingProfileModel = await athleteApi.getAthleteRecruitingProfile(athleteProfile.id, {as : 'admin'} );
			athleteProfile.InitProgress(recruitingProfile);
			processedDups.push(athleteProfile);
		}
		return processedDups;	
	}
	async findDuplicates(search: string): Promise<Array<AthleteProfileModel>> {
		const response: Array<AthleteProfileModel> = await CrudApi.Api(a => a.post(`${this.resource}/find-duplicates/${search}`));
		return this.processDuplicates(response);
	}
	async findUserDuplicates(athleteId: string): Promise<Array<AthleteProfileModel>> {
		try{
			const response: PaginatedResponse<AthleteProfileModel> = await CrudApi.Api(a => a.post(`${this.resource}/athlete-duplicates/${athleteId}`));
			if( response.count === 0 ) return [];
			return this.processDuplicates(response.docs);
		} catch(e) {
			return [];
		}
	}
	async mergeAccounts(athleteIds: Array<string>): Promise<void> {
		await CrudApi.Api(a => a.post(`${this.resource}/merge/${athleteIds.join('&')}`));
	}
	async mergeSkipAccounts(athleteIds: Array<string>): Promise<void> {
		await CrudApi.Api(a => a.post(`${this.resource}/merge-skip/${athleteIds.join('&')}`));
	}
}
export const athleteApi = new AthleteApi();