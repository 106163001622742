
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { BAIconsMixin, CurrentAthleteMixin, StripeMixin } from '@/mixins';
import PaymentMethod from '@/components/payments/PaymentMethod.vue';
import PaymentMethodForm from '@/components/payments/PaymentMethodForm.vue';
import PaymentHistoryTable from '@/components/payments/PaymentHistoryTable.vue';
import PaymentMethodInfoProvider from '@/components/hoc/payments/PaymentMethodInfoProvider.vue';
import PaymentHistoryInfoProvider from '@/components/hoc/payments/PaymentHistoryInfoProvider.vue';
import SubscriptionPlanInfoProvider from '@/components/hoc/SubscriptionPlanInfoProvider.vue';
import { PaymentMethod as StripePaymentMethod } from '@stripe/stripe-js';
import { PaymentMethodFormValue } from '@/../types/interfaces';
import { CustomerApi } from '@/api/CustomerApi';
import { notificationStore } from '@/store';
import { PaymentMethodModel } from '@/models/stripe/PaymentMethodModel';

@Component({
	components: {
		PaymentMethod,
		PaymentMethodForm,
		PaymentHistoryTable,
		PaymentMethodInfoProvider,
		PaymentHistoryInfoProvider,
		SubscriptionPlanInfoProvider,
	},
})
export default class AthleteSettingsPaymentForm extends Mixins(BAIconsMixin, CurrentAthleteMixin, StripeMixin){
	@Prop({ type: Boolean, default: false }) hideTitle: boolean; 
	showAddCard: boolean = false;
	get EditMode(): boolean{
		return this.showAddCard;
	}
	openAddCard(): void{
		this.showAddCard = true;
	}
	closeAddCard(): void{
		this.showAddCard = false;
	}
	$refs: {
		paymentMethodProviderRef: PaymentMethodInfoProvider;
	};
	submitLoading: boolean = false;
	async submitPaymentMethod(payload: PaymentMethodFormValue<StripePaymentMethod.Card>, addPaymentMethodToHistory: (stripePaymentMethod: PaymentMethodFormValue<StripePaymentMethod.Card>) => void): Promise<void>{
		if(payload.card === undefined){
			console.error("PaymentMethodResult not found, failed to setup card");
			return;
		}
		this.submitLoading = true;
		try{
			const setupIntent = await CustomerApi.createSetupIntent('athlete', this.CurrentAthleteId);
			await this.$stripe.confirmCardSetup(setupIntent.client_secret, {
				payment_method: payload.id
			});
			addPaymentMethodToHistory(payload);
		}catch(e){
			console.error(e);
			notificationStore.pushErrorNotificationMessage("Something went wrong, please try again later.");
		}
		this.submitLoading = false;
		this.showAddCard = false;
	}

	showConfirmDeletePaymentMethod: boolean = false;
	deletePaymentLoading: boolean = false;
	deleteValid: boolean = false;
	paymentMethodToDelete: PaymentMethodModel | null = null;
	get ConfirmDeleteTitle(): string{
		if(this.paymentMethodToDelete === null) return "Are you sure?";
		if(this.paymentMethodToDelete.Last4 === null) return "Are you sure?";
		return `Delete payment method ending in ${this.paymentMethodToDelete.Last4}?`;
	}
	confirmDeletePaymentMethod(paymentMethod: PaymentMethodModel): void{
		this.paymentMethodToDelete = paymentMethod;
		this.showConfirmDeletePaymentMethod = true;
	}
	cancelDeletePaymentMethod(): void{
		this.showConfirmDeletePaymentMethod = false;
		this.paymentMethodToDelete = null;
	}
	async deletePaymentMethod(deleteByPaymentMethodId: (id: string) => Promise<void>): Promise<void>{
		this.deletePaymentLoading = true;
		try{
			await deleteByPaymentMethodId(this.paymentMethodToDelete.stripePaymentMethodId);
		}catch(e){
			notificationStore.pushErrorNotificationMessage("Failed to delete card. Please try again later");
		}finally{
			this.deletePaymentLoading = false;
		}
		this.showConfirmDeletePaymentMethod = false;
		this.paymentMethodToDelete = null
	}
}
