
import { Component, Mixins } from 'vue-property-decorator';
import { VuetifyMixin, MyAthleteMixin, QRCodeMixin, BAIconsMixin } from '@/mixins';
import CopyToClipboard from './CopyToClipboard.vue';

@Component
export default class ShareQRDialog extends Mixins(VuetifyMixin, MyAthleteMixin, QRCodeMixin, BAIconsMixin) {
	showDialog: boolean = false;
  qrCode: string;

  mounted() {
    this.initializeQRCode()
  }

  async initializeQRCode() {
    this.qrCode = await this.generateQRCode(this.MyAthleteShareUrl);
  }
}

