
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { VuetifyMixin } from '@/mixins'

@Component
export default class LoadingWait extends Mixins(VuetifyMixin) {
    @Prop({default: "Loading information"}) text: string;
	@Prop({type: Boolean}) noSkeletonLoader: string;
	@Prop({type: Boolean}) loading: string;
}
