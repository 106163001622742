
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BAIconsMixin, ColorsMixin, VuetifyMixin } from '@/mixins';
import { TeamJersey } from '@/models/team';
import { cloneDeep } from 'lodash';

interface VueColor {
  hex: string;
  rgba: { r: number; g: number; b: number; a: number };
  hsl: { h: number; s: number; l: number; a: number };
  [key: string]: any; // To account for other possible color representations
}

@Component({
    components: {}
})
export default class TeamCreateTeamInfo extends Mixins(VuetifyMixin, BAIconsMixin, ColorsMixin) {
    @Prop() value: TeamJersey;

    editingColors: boolean = false;
    editingJerseyColor: boolean = false;
    editingJerseyAccent: boolean = false;
    editingJerseyNumber: boolean = false;

	vueColorJersey: VueColor = {
		hex: '#194d33',
	    rgba: { r: 25, g: 77, b: 51, a: 1 },
    	hsl: { h: 146, s: 49, l: 20, a: 1 }
	};
	vueColorAccent: VueColor = {
		hex: '#ffffff',
	    rgba: { r: 25, g: 77, b: 51, a: 1 },
    	hsl: { h: 146, s: 49, l: 20, a: 1 }
	};
	vueColorNumber: VueColor = {
		hex: '#ffffff',
	    rgba: { r: 25, g: 77, b: 51, a: 1 },
    	hsl: { h: 146, s: 49, l: 20, a: 1 }
	};

    backupJersey: TeamJersey;
    onEditJersey() {
        this.$emit('editStart', this.value);
        this.backupJersey = cloneDeep(this.value);
        this.vueColorJersey.hex = this.value.color;
        this.vueColorAccent.hex = this.value.accent;
        this.vueColorNumber.hex = this.value.number;
        this.editingColors = true;
    }
    onCancelEditJersey() {
        this.value = cloneDeep(this.backupJersey);
        this.$emit('cancel', this.value);
        this.editingColors = false;
    }
    onEditJerseyComplete() {
        this.$emit('input', this.value);
        this.$emit('change', this.value);
        this.editingColors = false;
    }
    onChangeJerseyColorDone(newColor: string) {
		this.value.color = newColor;
        this.editingJerseyColor = false;
        this.moreColors = false;
    }
	onChangeJerseyColor(newColor: VueColor) {
        this.onChangeJerseyColorDone(newColor.hex);
	}
    onChangeAccentColorDone(newColor: string) {
		this.value.accent = newColor;
        this.editingJerseyAccent = false;
        this.moreColors = false;
    }
	onChangeAccentColor(newColor: VueColor) {
		this.onChangeAccentColorDone(newColor.hex);
	}
    onChangeNumberColorDone(newColor: string) {
		this.value.number = newColor;
        this.editingJerseyNumber = false;
        this.moreColors = false;
    }
	onChangeNumberColor(newColor: VueColor) {
		this.onChangeNumberColorDone(newColor.hex);
	}
    onEditJerseyColor() {
        this.editingJerseyColor = true;
    }
    onEditJerseyAccent() {
        this.editingJerseyAccent = true;
    }
    onEditJerseyNumber() {
        this.editingJerseyNumber = true;
    }
}
