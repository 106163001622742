
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { mdiAlert } from '@mdi/js';
import { VuetifyMixin } from '@/mixins/VuetifyMixin';

@Component
export default class Alert extends Mixins(VuetifyMixin){
	@Prop({ default: mdiAlert }) icon: string;
	@Prop() iconSize: number | string;
	@Prop({ default: 'baColorSuperLightBlue' }) color: string;
	@Prop({ default: 'baColorPrimaryBlue' }) borderColor: string;
	@Prop({ type: Boolean }) hideIcon: boolean;
	@Prop({ type: Boolean }) dense: boolean;
	@Prop({ type: Boolean }) error: boolean;
	@Prop({ type: Boolean }) warning: boolean;

	get Color(): string{
		if(this.error) return 'baColorAlertRedBG';
		if(this.warning) return 'baColorLightWarning';
		return this.color;
	}
	get BorderColor(): string{
		if(this.error) return 'baColorAlertRed';
		if(this.warning) return 'baColorAccentGold';
		return this.borderColor;
	}

	get AlertStyle(): Record<string, string>{
		return {
			'color': `${this.getColor(this.IsDark ? 'baColorAppBackground' : 'baColorPrimaryText')}`,
			'background-color': `${this.getColor(this.Color)} !important`,
			'border': `1px solid ${this.getColor(this.BorderColor)}`,
		};
	}
}
