/* AdminRoutingMixin.ts */

import { Component, Mixins } from 'vue-property-decorator';
import { RoutingMixin } from "@/mixins";
import * as AdminRoutes from '@/../types/constants/admin.routes';

/**
 * Provides routing routines
 */
@Component
export class AdminRoutingMixin extends Mixins(RoutingMixin) {
	gotoUserAdmin(): void{
		this.gotoRoute({name: AdminRoutes.UserAdminDashboard});
	}

	gotoAthleteAdmin(): void {
		this.gotoRoute({name: AdminRoutes.AthleteAdminDashboard});
	}

	gotoAthleteRatingsAdmin() {
		this.gotoRoute({name: AdminRoutes. AthleteRatingsDashboard});
	}

	gotoSportAdmin(): void {
		this.gotoRoute({name: AdminRoutes.SportAdminDashboard});
	}

	gotoPartnerAdmin(): void {
		this.gotoRoute({name: AdminRoutes.PartnerAdminDashboard});
	}

	gotoBASchoolAdmin(): void {
		this.gotoRoute({name: AdminRoutes.BASchoolAdminDashboard});
	}

	gotoTeamsAdmin(): void{
		this.gotoRoute({name: AdminRoutes.TeamAdminDashboard});
	}

	gotoOrgsAdmin(): void{
		this.gotoRoute({name: AdminRoutes.OrganizationAdminDashboard});
	}

	gotoEventsAdmin(): void{
		this.gotoRoute({name: AdminRoutes.BAEventAdmin});
	}

	gotoEventsPhysicalAdmin() {
		this.gotoRoute({name: AdminRoutes.EventPhysical});
	}

	gotoSchoolSearchAdmin(): void{
		this.gotoRoute({name: AdminRoutes.SchoolSearchAdminDashboard});
	}

	gotoCoachSharesAdmin() {
		this.gotoRoute({name: AdminRoutes.CoachSharesAdminDashboard});
	}

	gotoPhysicalAssessmentsAdmin(): void {
		this.gotoRoute({name: AdminRoutes.VerifiedAssessmentAdminDashboard});
	}

	gotoAthleteReportsAdmin() {
		this.gotoRoute({name: AdminRoutes.AthleteReportsAdminDashboard});
	}

	gotoScoutingReportsAdmin(): void {
		this.gotoRoute({name: AdminRoutes.ScoutingReportsAdminDashboard});
	}

	gotoMindsetReportsAdmin(): void {
		this.gotoRoute({name: AdminRoutes.MindsetReportsAdminDashboard});
	}
}
