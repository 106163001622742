// ColorsMixin.ts

import { Component, Vue } from 'vue-property-decorator';
import { arrayTo2D, transpose } from '@/helpers';

@Component
export class ColorsMixin extends Vue {
    moreColors: boolean = false;

    baseColors = [
        '#FFFFFF', // white
        '#000000', // black
        '#FF0000', // red
        '#00FF00', // green
        '#0000FF', // blue
        '#FFD800', // yellow
        '#B200FF', // purple
        '#00FFFF', // cyan
        '#FF6A00', // orange
        '#FF00DC', // pink
    ]
 
    teamColorsBlue1 = [
        '#004d98', // Barcelona Blau
        '#6CABDD', // Manchester City
        '#0097DB', // Zenit
        '#1e71b8', // Atalanta
        '#034694', // Chelsea
    ]
    teamColorsBlue2 = [
        '#00428c', // Porto
        '#010E80', // Inter Milan
        '#004170', // PSG
        '#272e61', // Atletico
        '#132257', // Tottenham
    ]
    teamColorsBlue = [...this.teamColorsBlue1, ...this.teamColorsBlue2];
    teamColorsRed1 = [
        '#a50044', // Barcelona Grana
        '#fb090b', // AC Milan
        '#f43333', // Sevilla
        '#EF0107', // Arsenal
        '#E83030', // Benfica
    ]
    teamColorsRed2 = [
        '#DA291C', // Manchester United
        '#D2122E', // Ajax
        '#c8102E', // Liverpool
        '#B81137', // Toronto FC
        '#a90432', // Galatasaray
    ]
    teamColorsRed = [...this.teamColorsRed1, ...this.teamColorsRed2];
    teamColorsGreen = [
        '#65B32E', // Wolfsburg
        '#5D9741', // Seattle
        '#018749', // Celtic
    ]
    teamColorsYellow = [
        '#FFF200', // Norwich
        '#FDE100', // Dortmund
    ]
    teamColorsGreenYellow = [...this.teamColorsGreen, ...this.teamColorsYellow];
    teamColorsOther = [
        '#F0612C', // Shaktar
        '#f7b5cd', // Miami
        '#633492', // Orlando
        '#E4E5E6', // Minnesota
        '#241F20', // Newcastle
    ]
    teamColors: string[] = [ ...this.teamColorsBlue, ...this.teamColorsRed, ...this.teamColorsGreenYellow, ...this.teamColorsOther ];
    availableColors: string[] = [...this.baseColors, ...this.teamColors];
    someColors: string[] = [...this.baseColors, ...this.teamColorsBlue1, ...this.teamColorsRed1, ...this.teamColorsGreenYellow];

    colorArrayTo2D(colorArray: Array<string>): string[][] {
        return transpose(arrayTo2D(colorArray, colorArray.length / 5, 5));
    }
    ColorSwatches(type: string = "available"): Array<Array<string>> {
        if( type.includes('all') ) return undefined;    // show all colors
        if( type.includes('more') ) return undefined;
        if( type.includes('bas') ) return this.colorArrayTo2D(this.baseColors);
        if( type.includes('some') ) return this.colorArrayTo2D(this.someColors);
        return this.colorArrayTo2D(this.availableColors);
    }
}