
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin, StringsMixin } from '@/mixins';
import { TeamJersey } from '@/models/team';
import JerseySvg from "@/components/svg/JerseySvg.vue";
import { StyleValue } from 'vue/types/jsx';

@Component({
	components: { JerseySvg }
})
export default class Jersey extends Mixins(VuetifyMixin, StringsMixin){
	@Prop({default: '?', type: Number}) number: string;
	@Prop({default: 10, type: Number}) size: number;
	@Prop() teamJersey: TeamJersey;

	get JerseyColor(): string {
		if( this.IsEmpty(this.teamJersey?.color) ) return "#005BA7";
		return this.teamJersey.color;
	}
	get JerseyColorAccent(): string {
		if( this.IsEmpty(this.teamJersey?.accent) ) return "#FFFFFF";
		return this.teamJersey.accent;
	}
	get JerseyColorNumber(): string {
		if( this.IsEmpty(this.teamJersey?.number) ) return "#F2B84E";
		return this.teamJersey.number;
	}
	get JerseyNumber(): string {
		if( this.IsEmpty(this.number) ) return '?';
		return this.number;
	}
	get NumberStyle(): StyleValue {
		return {
			// 'z-index': 100,
			'font-size': `${this.size*1.7}px`,
			'color': this.JerseyColorNumber,
			position: 'relative',
			left: `-50%`
		}
	}
	get SvgStyle() {
		return {
			width: `${this.size * 5}px`,
			height: 'auto'
		}
	}
}
