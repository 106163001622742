
import { DataTableHeader, DataPagination, DataOptions, DataTableFilterFunction } from 'vuetify';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { AuthMixin, DebounceMixin, CoachRoutingMixin, SportsSelectorMixin, VuetifyMixin, AppHostnameMixin, StringsMixin, TeamAthletesMixin, BAIconsMixin } from '@/mixins';
import { TeamModel, PlayerOnTeam, TeamAthleteProgress, AthleteProgressFields } from '@/models/team';
import { teamDashboardStore, notificationStore } from '@/store';
import { PatchPlayerParams, RemovePlayerParams } from '@/store/teams/TeamDashboard.store';
import { SportPosition } from '@/../types/enums';
import { ShareTeam, EditTeam } from '@/../types/constants/web_client_user.routes';
import { athleteApi } from '@/api/AthleteApi';
import { teamApi } from '@/api/TeamApi';
import { AthleteProfileModel } from '@/models/athlete/AthleteProfileModel';
import { InvitePlayerFormValue } from '@/../types/interfaces';

@Component
export default class TeamRoster extends Mixins(AuthMixin, DebounceMixin, CoachRoutingMixin, SportsSelectorMixin, VuetifyMixin, AppHostnameMixin, StringsMixin, BAIconsMixin, TeamAthletesMixin){
	@Prop({ default: 590 }) tableHeight: number;
	@Prop({ type: Boolean }) readOnly;
	@Prop({ required: true }) team: TeamModel;

	AthleteProgressFields = AthleteProgressFields;

	loadingAthletes: boolean = false;

	showDetails: boolean = false;
	get Players(): any[] {
		if( this.showDetails ) return this.teamAthletes;
		return this.team.players;
	}
	async onToggleDetails() {
		if( !this.teamAthletes || this.teamAthletes.length == 0 ) {
			await this.loadTeamAthletes();
		}
		this.showDetails = !this.showDetails		
	}
	ProgressInfo(teamAthlete: TeamAthleteProgress): Partial<TeamAthleteProgress> {
		return {
			hasPhoto: teamAthlete.hasPhoto,
			hasEmail: teamAthlete.hasEmail,
			hasPhone: teamAthlete.hasPhone,
			hasInsta: teamAthlete.hasInsta,
			hasTwitter: teamAthlete.hasTwitter,
			hasBio: teamAthlete.hasBio,
			hasDOB: teamAthlete.hasDOB,
			hasHeight: teamAthlete.hasHeight,
			hasWeight: teamAthlete.hasWeight,
			hasDominant: teamAthlete.hasDominant,
			hasLocation: teamAthlete.hasLocation,
			hasHighlights: teamAthlete.hasHighlights,
			hasGradYear: teamAthlete.hasGradYear,
			hasGPA: teamAthlete.hasGPA,
			hasSAT: teamAthlete.hasSAT,
			hasVerifiedAssessment: teamAthlete.hasVerifiedAssessment,
			mindsetArchetype: teamAthlete.mindsetArchetype,
			hasScoutingReport: teamAthlete.hasScoutingReport,
			commitment: teamAthlete.commitment,
		}
	}

	linkingAthlete: boolean = false;
	editPlayer: PlayerOnTeam;
	linkAthleteProfile: AthleteProfileModel;
	onLinkAthlete(athlete: PlayerOnTeam) {
		this.linkingAthlete = true;
		this.editPlayer = athlete;
	}
	onAthleteLinkSelected( athlete: AthleteProfileModel ){
		this.editPlayer.athleteId = athlete.id?? '';
		if( !!athlete.primaryPosition ) {
			this.editPlayer.position = (athlete.primaryPosition as unknown) as SportPosition;
		}
	}
	async onCancelLinkPlayer() {
		this.linkingAthlete = false;
		this.editPlayer = undefined;
		this.loadTeamAthletes();
	}
	async onAcceptLinkPlayer() {
		if( this.IsNotEmpty(this.editPlayer?.id) ) this.patchTeamPlayer(this.editPlayer);
		this.linkingAthlete = false;
		this.editPlayer = undefined;
	}

	goToEditRoster(step?: string){
		this.$router.push({
			name: EditTeam,
			params:{
				teamId: this.team.id,
				currentStep: step,
			}
		});
	}
	async playerVisibility(player: PlayerOnTeam) {
		try{
			await athleteApi.setVisibility(player?.athleteId);
			notificationStore.pushSnackbarSuccess({message:`Athlete visibility updated for  ${this.FullName(player.firstName, player.lastName)}`});
		} catch(e) {
			notificationStore.pushSnackbarError({message:`Error updating athlete ${e}`});
		}
	}

	showRemovePlayerDialog: boolean = false;
	selectedPlayerId: string = "";
	confirmRemovePlayer(player: PlayerOnTeam): void{
		this.selectedPlayerId = player.id;
		this.showRemovePlayerDialog = true;
	}
	async removeSelectedPlayer(): Promise<void>{
		this.showRemovePlayerDialog = false;
		await teamDashboardStore.removePlayer({
			teamId: this.team.id,
			playerId: this.selectedPlayerId,
		});
	}

	get TableStyle():  Record<string,string>{
		return {
			'overflow-y': 'auto',
			'max-height': `${this.tableHeight}px`,
		}
	}
	get TableLoading(): boolean{
		return teamDashboardStore.patchPlayerLoading;
	}

	tableOptions: DataOptions = {
		page: 0,
		itemsPerPage: -1,
		sortBy: ['number'],
		sortDesc: [false],
		groupBy: [],
		groupDesc: [false],
		multiSort: false,
		mustSort: false,
	};
	get TableSortBy(): string{
		const [value] = this.tableOptions.sortBy;
		return value;
	}
	get TableSortDesc(): boolean{
		const [value] = this.tableOptions.sortDesc;
		return value;
	}

	async patchTeamPlayer(player: Partial<PlayerOnTeam>) {
		const teamId = this.team?.id;
		const playerId = player?.id;
		const playerPatch = {...player};
		const params: PatchPlayerParams = {
			teamId,
			playerId,
			playerPatch,
		};
		await teamDashboardStore.patchPlayer(params);
		this.expandedItems = [];
	}
	async removeTeamPlayer(player: Partial<PlayerOnTeam>) {
		const teamId = this.team?.id;
		const playerId = player?.id;
		const params: RemovePlayerParams = {
			teamId,
			playerId,
		};
		await teamDashboardStore.removePlayer(params);
		this.expandedItems = [];
	}
	async addTeamPlayer(player: PlayerOnTeam, team: Partial<TeamModel>) {
		if( this.IsEmptyArray(team.players) ) team.players = [];
		await team.players.push(player);
		await teamApi.patch(team);
	}

	sortDown(header, on: any): void{
		console.log("sortDown",header, on);
		on.sort(header.name);
	}

	search: string = "";
	pagination: DataPagination;
	get Headers(): DataTableHeader<any>[] {
		const headers: DataTableHeader<any>[] = [];

		// do not show number when athlete detail are shown
		if( !this.showDetails ) headers.push({ text: 'No.', value: 'number' });
		
		headers.push({ text: 'Name', value: 'lastName' });

		// set up columns depending on what informaiton is to be shown.
		if( this.showDetails ) {
			headers.push(
				{ text: 'Email', value: 'email', sortable: false },
				{ text: 'AthleteId', value: 'athleteId', sortable: false },
				{ text: 'DoB', value: 'dateOfBirth', sortable: false },
			)
		}
		else {
			headers.push(
				{ text: 'Pos', value: 'position', sortable: false },
			)
			if( !this.IsAdmin ) {
				headers.push(
					{ text: 'Health', value: 'healthy', sortable: false, align: 'center' },
					{ text: 'Avail', value: 'available', sortable: false, align: 'center' },
				);
			}
			headers.push(
				{ text: '', value: 'actions', sortable: false},
			);
		}

		headers.push({text: '', value: 'data-table-expand'});

		return headers;
	}
	expandedItems: any[] = [];
	toggleExpandRow(item: PlayerOnTeam, isExpanded: boolean, expand: (item, isExpanded) => void): void{
		if(this.readOnly) return;
		if(isExpanded){
			this.expandedItems = [];
		}else{
			expand(item, !isExpanded);
		}
	}
	playerFilter: DataTableFilterFunction = (value: any, search: string | null, item: PlayerOnTeam): boolean => {
		const query = new RegExp(search, 'ig');
		return query.test(item.firstName) ||
		query.test(item.lastName) ||
		query.test(item.position) ||
		query.test(item.number);
	}

	get HeaderStyle(): Record<string,string> {
		return {
			'border-top': `1px solid #074E69 !important`,
			'background-color': this.getColor('baColorDeepBlue'),
		}
	}
	get SearchLabel(): string {
		if( this.search.length === 0 ) return `Search ${this.team.players.length} players`;
		return ``
	}

	async onShareRoster() {
		try{
			await teamApi.shareTeam(this.team.id);
		} catch(e) {
			console.log(e);
			return;
		}
		this.$router.push({
			name: ShareTeam,
			params:{
				teamId: this.team.id,
				owner: 'coach',
				sharingUrlId: 'share'
			}
		});
	}

	invitationInProgress: boolean = false;
	invitedCount: number = 0;
	async onInviteAll(team: TeamModel) {
		this.invitationInProgress = true;
		this.invitedCount = 0;
		for( const player of team.players ) {
			if( this.IsEmpty( player.athleteId ) ) {
				const playerToInvite: InvitePlayerFormValue = {
					id: player.id,
					number: player.number,
					firstName: player.firstName,
					lastName: player.lastName,
					email: player.email,
					position: player.position,
				};
				await teamDashboardStore.invitePlayerByEmail({
					teamId: team.id,
					email: playerToInvite.email,
					player: playerToInvite,
				});
				this.invitedCount++;
			}
		}
		this.invitationInProgress = false;
		notificationStore.pushSnackbarSuccess({message:`Invited ${this.invitedCount} athletes`});
	}

	movingAthlete: boolean = false;
	playerMoveToTeam: TeamModel;
	isMoveTeamSelected: boolean = false;
	onMovePlayer(player: PlayerOnTeam) {
		this.movingAthlete = true;
		this.editPlayer = player;
	}
    async onSelectMoveToTeam(team: TeamModel) {
        this.playerMoveToTeam = team;
		this.isMoveTeamSelected = true;
    }
	async onCancelMovePlayer() {
		this.movingAthlete = false;
		this.editPlayer = undefined;
	}
	async onAcceptMovePlayer() {
		if( this.IsNotEmpty(this.playerMoveToTeam) && this.IsNotEmpty(this.editPlayer?.id) ) {
			if( confirm(`Move ${this.editPlayer.firstName} from ${this.team.name} to ${this.playerMoveToTeam.name}? This action cannot be undone`) ) {
				this.addTeamPlayer(this.editPlayer, this.playerMoveToTeam);
				this.removeTeamPlayer(this.editPlayer);
			}
		}
		this.movingAthlete = false;
		this.editPlayer = undefined;
	}
}
