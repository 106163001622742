
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { StringsMixin } from '@/mixins';

@Component({
	components: { },
})
export default class SimpleChip extends Mixins(StringsMixin) {
    @Prop() item: any;
    @Prop() image: string;
    @Prop() text: string;
    @Prop( { default: 20 } ) imageSize: number;
    @Prop( { default: "mr-1" } ) imageClass: string;
    @Prop( { default: "text-caption" } ) textClass: string;
    @Prop( { type: Boolean } ) link;
    @Prop( { type: Boolean } ) noWrap;

    get TextClass(): string {
        var textClass: string = this.textClass;
        if( this.link ) textClass += " primary--text cursor-pointer";
        if( this.noWrap ) textClass += " text-no-wrap text-truncate";
        return textClass;
    }

    onClick() {
        if( !this.link ) return;
        this.$emit('click');
    }
    onClickImage() {
        this.onClick();
        if( this.IsEmpty(this.image) ) return;
        this.$emit('clickImage');
    }
    onClickText() {
        this.onClick();
        if( this.IsEmpty(this.text) ) return;
        this.$emit('clickText');
    }
}
