
import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator';
import { VuetifyMixin, AthleteRoutingMixin, BAIconsMixin } from '@/mixins';
import { mdiYoutube } from '@mdi/js';
import SectionHeading from "@/components/ui/SectionHeading.vue";
import Alert from "@/components/ui/Alert.vue";
import { filesize } from "@/pipes";
import { getMediumDateString } from "@/helpers";
import ProgressBar from "@/components/charts/ProgressBar.vue";

export enum VideoUploadDialogVariant {
	BASIC,
	SHOWCASE,
	ELITE
}

@Component({
	components: {
		SectionHeading,
		Alert,
		ProgressBar
	}
})
export class VideoUploadDialog extends Mixins(VuetifyMixin, AthleteRoutingMixin, BAIconsMixin){
	@PropSync('show',{ default: false, type: Boolean }) Show: boolean;
	@Prop({ default: false, type: Boolean }) dark: boolean;

	@Prop({ default: VideoUploadDialogVariant.BASIC }) variant: VideoUploadDialogVariant;

	@PropSync('file', { default: null, type: File }) VideoFile: File | null;
	@Prop({ default: null, type: Number }) uploadProgress: number | null;
	@Prop({ default: 0, type: Number }) videoHoursUsed: number;
	@Prop({ default: 0, type: Number }) maxVideoHours: number;

	@Prop({ default: 0, type: Number }) youtubeUsed: number;
	@Prop({ default: 1, type: Number }) maxYoutubeVideos: number;
	@PropSync('youtubeUrl', { default: '', type: String }) YoutubeUrl: string;

	eagerYoutubeUrl = ''

	mdiYoutube = mdiYoutube;
	filesize = filesize
	getMediumDateString = getMediumDateString;

	fileThumnail = '';
	test = ''

	$refs: {
		fileInput: HTMLInputElement
		thumbnailVid: HTMLSourceElement
	}

	get PlanName(): string {
		if( this.IsBasic ) return "Standard";
		if( this.IsShowcase ) return "Showcase";
		if( this.IsElite) return "Elite";
		return "Basic"
	}
	get IsBasic(): boolean {
		return this.variant === VideoUploadDialogVariant.BASIC;
	}
	get IsShowcase(): boolean {
		return this.variant === VideoUploadDialogVariant.SHOWCASE;
	}
	get IsElite(): boolean {
		return this.variant === VideoUploadDialogVariant.ELITE;
	}
	get PlanText(): string {
		return `Your ${this.PlanName} Subscription allows you to upload ${this.maxYoutubeVideos} videos from YouTube`;
	}

	getPercentColor(value: number): string {
		if (value < 50) {
			return 'baColorGreen1'
		}
		else if (value < 100) {
			return 'yellow'
		}
		else {
			return 'baColorRed'
		}
	}

	get YoutubeFullPercent(): number{
		return this.youtubeUsed  / this.maxYoutubeVideos * 100
	}
	get YoutubeProgressBarColor(): string {
		return this.getPercentColor(this.YoutubeFullPercent)
	}

	async detectVideoFiles($event: Event): Promise<void> {
		this.VideoFile = ($event.target as HTMLInputElement).files[0]
	}
	async addYoutube(validate: () => Promise<boolean>): Promise<void> {
		const valid = await validate();
		
		if (valid && this.eagerYoutubeUrl.trim().length > 0) {
			this.YoutubeUrl = this.eagerYoutubeUrl.trim();
			this.$emit('addyoutube', this.eagerYoutubeUrl);
		}
	}

	get VideoHoursPercentage(): number {
		return Math.ceil(this.videoHoursUsed * 100 / this.maxVideoHours)
	}
	get VideoHoursColor(): string {
		return this.getPercentColor(this.VideoHoursPercentage);
	}

}

export default VideoUploadDialog;
