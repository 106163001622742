import { AccessControlledModel } from '../AccessControlledModel';
import { PhysicalAssessmentData } from '@/../types/interfaces';
import { DevelopmentalAge, Gender } from '@/../types/enums';
import { AgeGroup, Under13AgeGroup, BaseSoccerPosition, DominantSide, SoccerPosition } from '@best-athletes/ba-types';
import { TeamModel } from '@/models/team';
import { genderType, isEmpty, isNotEmpty } from '@/pipes';
import { ageToAgeGroup } from "@/../types";
import { AthleteProfileModel } from '..';

export class AthleteAssessmentDataModel extends AccessControlledModel implements PhysicalAssessmentData {
	readonly __type = 'AthleteAssessmentDataModel';
	athleteId?: string;
	teamId?: string;
	teamIds?: string;
	organizationId?: string;
	dateCreated: Date = new Date();

	assessmentDate: Date; // MM/DD/YYYY
	playingLevel: AgeGroup | Under13AgeGroup; // U14, U13, etc
	competitiveLevel: number; // 1-5
	gender: Gender; // M/F
	dominantFoot: string; // L/R/N
	playingPosition: string; // Defensive Midfielder, etc
	dateOfBirth: string; // (DD/MM/YYYY)
	ageYear: number; // age in years excluding months
	ageMonthRemainder: number; // remainder of age in months
	ageMonthDecimal: number; // =ageMonthRemainder/12
	age: number; // ageYear + ageMonthDecimal

	mass: number; // kg
	standingHeight: number; // cm
	trueSittingHeight: number; // cm =sittingHeightWithBox - boxHeight
	sittingHeightWithBox: number; // cm height of athlete sitting on box including box
	boxHeight: number; // box that athlete sits on height
	legLength: number; // standingHeight - sittingHeightWithBox
	legTrunk: number; // =(height - sittingHeight)*sittingHeight
	ageLeg: number; // =age*(height-sittingHeight)
	ageSittingHeight: number; // =age * sittingHeight
	ageMass: number; // =age * mass
	massHeightRatio: number; // =(mass/height)*100
	bodyMassIndex: number; // =mass/((height/100)^2)

	maturityOffset: number; // =-9.236+(0.0002708* (legTrunk) )-(0.001663* (ageLeg) )+(0.007216* (ageSittingHeight) )+(0.02292* (massHeightRatio) )
	ageOfPeakHeightVelocity: number; // age-maturityOffset
	developmentalCategory: DevelopmentalAge; // Early/Average/Late

	// Power
	counterMovementJumpHeight: number; // cm
	power: number; // W =60.7*(mass)+45.3*(counterMovementJumpHeight)-2055 // I think there might be a mistake here

	// Agility
	dropJumpContactTime: number; // s
	dropJumpHeight: number; // cm
	reactiveStrengthIndex: number; // index =dropJumpHeight/dropJumpContactTime*100

	// Acceleration and Speed
	tenMeterSprint: number; // s
	acceleration: number; // m/s =(10/(tenMeterSprint)*3.6)/3.6
	twentyMeterSprint: number; // s
	thirtyFiveMeterSprint: number; // s
	twentyToThirtyFiveMeterSplit: number; // s
	speed: number; // m/s =(15/AF2*3.6)/3.6

	// Recovery
	yoyoIntermittentRecoveryTestStage: number; // m
	yoyoIntermittentRecoveryTestDistance: number;
	maximalAerobicVelocity: number; // km/h approx (yoyoIntermittentRecoveryTestStage-14) * 0.5 + 14

  	teams?: TeamModel[];
	tags: string = "";

	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		if (obj['assessmentDate']) this.assessmentDate = new Date(obj['assessmentDate']);
		if (obj['dateCreated']) this.dateCreated = new Date(obj['dateCreated']);
		return this;
	}

	get AgeGroup(): AgeGroup | Under13AgeGroup {
		return ageToAgeGroup(this.age, { allowUnder13: true });
	}
	get Gender(): Gender {
		return genderType(this.gender);
	}
	set Gender(value) {
		this.gender = value;
	}
	get Position(): BaseSoccerPosition {
		if( !this.playingPosition ) return undefined;

		switch( this.playingPosition ) {
			case BaseSoccerPosition.Goalkeeper:
			case SoccerPosition.Goalkeeper:
				return BaseSoccerPosition.Goalkeeper;

			case BaseSoccerPosition.Defender:
			case SoccerPosition.RightFullback:
			case SoccerPosition.LeftFullback:
			case SoccerPosition.CenterBack:
				return BaseSoccerPosition.Defender;

			case BaseSoccerPosition.Midfielder:
			case SoccerPosition.DefendingMidfielder:
			case SoccerPosition.RightMidfielder:
			case SoccerPosition.CentralMidfielder:
			case SoccerPosition.AttackingMidfielder:
			case SoccerPosition.LeftMidfielder:
				return BaseSoccerPosition.Midfielder;
			
			case BaseSoccerPosition.Forward:
			case SoccerPosition.Striker:
				return BaseSoccerPosition.Forward;
		}

		const position = this.playingPosition.toUpperCase();
		switch( position ) {
			case 'GOALKEEPER':
			case 'GK':
			case 'G':
				return BaseSoccerPosition.Goalkeeper;

			case 'DEFENDER':
			case 'DF':
			case 'LB':
			case 'CB':
			case 'RB':
				return BaseSoccerPosition.Defender;

			case 'MIDFIELDER':
			case 'MF':
			case 'CDM':
			case 'CM':
			case 'CAM':
			case 'LM':
			case 'RM':
				return BaseSoccerPosition.Midfielder;

			case 'FORWARD':
			case 'STRIKER':
			case 'FW':
			case 'ST':
			case 'LW':
			case 'RW':
				return BaseSoccerPosition.Forward;
		}
		return BaseSoccerPosition.Defender;
	}
	set Position(value) {
		this.playingPosition = value;
	}
	get Side(): DominantSide {
		if( !this.dominantFoot ) return undefined;

		switch( this.dominantFoot ) {
			case DominantSide.Right:
				return DominantSide.Right;

			case DominantSide.Left:
				return DominantSide.Left;

			case DominantSide.Ambidextrous:
				return DominantSide.Ambidextrous;							
		}

		const side = this.dominantFoot.toUpperCase();
		switch( side ) {
			case 'RIGHT':
			case 'R':
				return DominantSide.Right;

			case 'LEFT':
			case 'L':
				return DominantSide.Left;

			case 'AMBIDEXTROUS':
			case 'A':
			case 'BOTH':
			case 'B':
				return DominantSide.Ambidextrous;
		}
	}
	set Side(value) {
		this.dominantFoot = value;
	}
	get Mass(): string {
		if( !this.mass ) return '';
		return this.mass.toFixed(1);
	}
	set Mass(value) {
		this.mass = Number(value);
	}
	get Height(): string {
		if( !this.standingHeight ) return '';
		return this.standingHeight.toFixed(1);
	}
	set Height(value) {
		this.standingHeight = Number(value);
	}
	get SittingHeight(): string {
		if( !this.sittingHeightWithBox ) return ''
		return this.sittingHeightWithBox.toFixed(1);
	}
	set SittingHeight(value) {
		this.sittingHeightWithBox = Number(value);
	}
	get agility(): number {
		return this.reactiveStrengthIndex;
	}
	get recovery(): number {
		return this.yoyoIntermittentRecoveryTestStage;
	}

	computeAcceleration() {
		this.acceleration = 10.0 / this.tenMeterSprint;				// 10m in X seconds
	}
	Acceleration(measured: boolean = false, units: boolean = true): string {
		if( isEmpty(this.tenMeterSprint) ) return '';
		if( measured ) return `${this.TenMeters}${units?' s':''}`;
		if( !this.acceleration ) this.computeAcceleration();
		return `${this.acceleration.toFixed(2)}${units?' m/s':''}`;
	}
	computeSpeed() {
		this.speed = 15.0 / this.twentyToThirtyFiveMeterSplit;		// 15m in X seconds
	}
	Speed(measured: boolean = false, units: boolean = true): string {
		if( isEmpty(this.twentyToThirtyFiveMeterSplit) ) return '';
		if( measured ) return `${this.twentyToThirtyFiveMeterSplit.toFixed(2)}${units?' s':''}`;
		if( !this.speed ) this.computeSpeed();
		return `${this.speed.toFixed(2)}${units?' m/s':''}`;
	}
	computeSprint() {
		this.twentyToThirtyFiveMeterSplit = undefined;
		if( isNotEmpty(this.thirtyFiveMeterSprint) && isNotEmpty(this.twentyMeterSprint) ) this.twentyToThirtyFiveMeterSplit = this.thirtyFiveMeterSprint - this.twentyMeterSprint;
		this.speed = undefined;
        this.acceleration = undefined;
        this.computeAcceleration();
        this.computeSpeed();
	}
	computeAgility() {
		this.reactiveStrengthIndex = this.dropJumpHeight / this.dropJumpContactTime / 100.0;
	}
	Agility(measured: boolean = false, units: boolean = true): string {
		if( !this.dropJumpContactTime || !this.dropJumpHeight ) return '';
		if( measured ) return `${this.DropJumpHeight}${units?' cm':''}`;
		if( !this.reactiveStrengthIndex ) this.computeAgility();
		return `${this.reactiveStrengthIndex.toFixed(2)}`
	}
	computePower() {
		this.power = 60.7 * this.mass + 45.3 * this.counterMovementJumpHeight - 2055;
	}
	Power(measured: boolean = false, units: boolean = true): string {
		if( !this.counterMovementJumpHeight ) return '';
		if( measured ) return `${this.CMJumpHeight}${units?' cm':''}`;
		if( !this.power ) this.computePower();
		return `${this.power.toFixed(1)}${units?' W':''}`
	}
	computeRecovery() {
		this.yoyoIntermittentRecoveryTestDistance = this.yoyoIntermittentRecoveryTestStage < 14? 
			120 * this.yoyoIntermittentRecoveryTestStage - 1220 : 
			320 * this.yoyoIntermittentRecoveryTestStage - 4000;
		this.maximalAerobicVelocity = 0.5 * this.yoyoIntermittentRecoveryTestStage + 7.2;
	}
	Recovery(measured: boolean = false, units: boolean = true): string {
		if( !this.yoyoIntermittentRecoveryTestStage ) return '';
		if( measured ) return `${units?'stage ':''}${this.YoYoStage}`;
		if( !this.yoyoIntermittentRecoveryTestStage || !this.maximalAerobicVelocity ) this.computeRecovery();
		return `${this.maximalAerobicVelocity.toFixed(1)}${units?' km/h':''}`
	}

	get TenMeters(): string {
		if( !this.tenMeterSprint ) return ''
		return this.tenMeterSprint.toFixed(2);
	}
	set TenMeters(value) {
		this.tenMeterSprint = Number(value);
	}
	get TwentyMeters(): string {
		if( !this.twentyMeterSprint ) return ''
		return this.twentyMeterSprint.toFixed(2);
	}
	set TwentyMeters(value) {
		this.twentyMeterSprint = Number(value);
	}
	get ThirtyFiveMeters(): string {
		if( isEmpty( this.thirtyFiveMeterSprint ) ) return ''
		return this.thirtyFiveMeterSprint.toFixed(2);
	}
	set ThirtyFiveMeters(value) {
		this.thirtyFiveMeterSprint = Number(value);
	}
	get CMJumpHeight(): string {
		if( !this.counterMovementJumpHeight ) return ''
		return this.counterMovementJumpHeight.toFixed(1);
	}
	set CMJumpHeight(value) {
		this.counterMovementJumpHeight = Number(value);
	}
	get DropJumpTime(): string {
		if( !this.dropJumpContactTime ) return ''
		return this.dropJumpContactTime.toFixed(3);
	}
	set DropJumpTime(value) {
		this.dropJumpContactTime = Number(value);
	}
	get DropJumpHeight(): string {
		if( !this.dropJumpHeight ) return ''
		return this.dropJumpHeight.toFixed(1);
	}
	set DropJumpHeight(value) {
		this.dropJumpHeight = Number(value);
	}
	get YoYoStage(): string {
		if( !this.yoyoIntermittentRecoveryTestStage ) return ''
		return this.yoyoIntermittentRecoveryTestStage.toFixed(1);
	}
	set YoYoStage(value) {
		this.yoyoIntermittentRecoveryTestStage = Number(value);
	}
}

export class AthleteAssessmentDataModelEx extends AthleteAssessmentDataModel {
	athlete: AthleteProfileModel;
}