
import { Component, Prop, Vue } from 'vue-property-decorator';
import { isNotEmpty } from '@/pipes';

@Component
export default class TooltipIcon extends Vue {
  @Prop() icon: string;
  @Prop({default: "Do your homework"}) tooltip: string;
  @Prop({default: "top"}) pos: string = "top";
  @Prop({default: "primary"}) color: string;
  @Prop() tooltipColor: string;
  @Prop({type: Boolean}) large;
  @Prop({type: Boolean}) small;

  get TooltipColor(): string{
    if( isNotEmpty(this.tooltipColor) ) return this.tooltipColor;
    return this.color;
  }
}
