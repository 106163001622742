// env.ts

import { config } from '@/config/config';
import { EnvironmentName } from '@/../types/constants/environments';
import { AdminAppHostname, AthleteAppHostname, ClubAdminAppHostname, PublicAppHostname, CoachingAppHostname } from '@/../types/constants/hostnames';
import { CDN } from "@/../types/constants/cdns";

export function Environment(): EnvironmentName {
    return config.BA_ENV;
}

export function httpUrl(url: string, page: string = ''): string{
    const fullUrl = `${url}/${page}`.replace('//','/');
    return `https://${fullUrl}`;
}

export function AppUrlAdmin(): AdminAppHostname {
    switch( Environment() ) {
    case 'local':
        return 'admin.local.bestathletes.co:8080';
    case 'dev':
        return 'admin.dev.bestathletes.co';
    case 'test':
    case 'testing':
        return 'admin.testing.bestathletes.co';
    case 'stage':
    case 'staging':
        return 'admin.stage.bestathletes.co';
    case 'production':
        return 'admin.app.bestathletes.co';
    default:
        return 'admin.local.bestathletes.co:8080';
    }
}
export function AppUrlAthlete(): AthleteAppHostname {
    switch( Environment() ) {
    case 'local':
        return 'app.local.bestathletes.co:8080';
    case 'dev':
        return 'dev.bestathletes.co';
    case 'test':
    case 'testing':
        return 'testing.bestathletes.co';
    case 'stage':
    case 'staging':
        return 'stage.bestathletes.co';
    case 'production':
        return 'app.bestathletes.co';
    default:
        return 'app.local.bestathletes.co:8080';
    }
}
export function AppUrlCoach(): CoachingAppHostname {
    switch( Environment() ) {
    case 'local':
        return 'app.local.bestathletes.co:8080/c';
    case 'dev':
        return 'dev.bestathletes.co/c';
    case 'test':
    case 'testing':
        return 'testing.bestathletes.co/c';
    case 'stage':
    case 'staging':
        return 'stage.bestathletes.co/c';
    case 'production':
        return 'app.bestathletes.co/c';
    default:
        return 'app.local.bestathletes.co:8080/c';
    }
}
export function AppUrlClubAdmin(): ClubAdminAppHostname {
    switch( Environment() ) {
    case 'local':
        return 'club.local.bestathletes.co:8080';
    case 'dev':
        return 'club.dev.bestathletes.co';
    case 'test':
    case 'testing':
                return 'club.testing.bestathletes.co';
    case 'stage':
    case 'staging':
        return 'club.stage.bestathletes.co';
    case 'production':
        return 'club.app.bestathletes.co';
    default:
        return 'club.local.bestathletes.co:8080';
    }
}
export function AppUrlPublic(): PublicAppHostname {
    switch( Environment() ) {
    case 'local':
        return 'app.local.bestathletes.co:8080/public';
    case 'dev':
        return 'dev.bestathletes.co/public';
    case 'test':
    case 'testing':
        return 'testing.bestathletes.co/public';
    case 'stage':
    case 'staging':
        return 'stage.bestathletes.co/public';
    case 'production':
        return 'app.bestathletes.co/public';
    default:
        return 'app.local.bestathletes.co:8080/public';
    }
}

export function BaseUrlCDN(): CDN {
    switch( Environment() ) {
    case 'local':
        return 'cdn-dev.bestathletes.co';
    case 'dev':
        return 'cdn-dev.bestathletes.co';
    case 'test':
    case 'testing':
        return 'cdn-testing.bestathletes.co';
    case 'stage':
    case 'staging':
        return 'cdn-stage.bestathletes.co';
    case 'production':
        return 'cdn.bestathletes.co';
    default:
        return 'cdn.bestathletes.co';
    }
}

