// BAIconsMixin.ts

import { Component, Vue } from 'vue-property-decorator';
import { baIcons } from '@/../types/constants/baIcons'

@Component
export class BAIconsMixin extends Vue {
    BAIcons = baIcons;

    NumberedIcon(n: number, style: string = 'box') {
        // return "numeric-1-box"
        return `mdi-numeric-${n}-${style}`
    }
}