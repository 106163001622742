
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BAIconsMixin, StringsMixin, VuetifyMixin } from '@/mixins';

@Component
export class ConfirmationDialog extends Mixins(VuetifyMixin, BAIconsMixin, StringsMixin){
	@Prop({ type: Boolean }) show;
	@Prop({ type: Boolean }) error;
	@Prop({ type: Boolean }) warning;
	@Prop({ default: 500, type: Number }) width: number;
	@Prop({ type: Boolean }) hideTitle;
	@Prop({ type: Boolean }) loading;
	input(show: boolean): void{ this.$emit('update:show', show); }

	@Prop({ default: null }) icon: string | null;
	@Prop({ default: "Are you sure?" }) titleText: string;
	@Prop({ default: "This action cannot be undone." }) bodyText: string;
	@Prop({ default: "Cancel" }) cancelText: string;
	@Prop({ default: "Confirm" }) confirmText: string;

	@Prop() taskButton: string;

	@Prop({ type: Boolean }) dark: boolean;
	@Prop({ default: undefined, type: String }) color: string;

	get Fullscreen(): boolean{
		return this.IsMobile;
	}

	onConfirm($event: Event): void{
		$event.preventDefault();
		this.$emit('confirm');
		this.input(false);
	}
	onCancel(): void{
		this.$emit('cancel');
		this.input(false);
	}
	onTaskButton() {
		this.$emit('task');
	}

	get hasTaskButton(): boolean {
		return this.IsNotEmpty(this.taskButton);
	}
}
export default ConfirmationDialog;
