// ColorMixin.ts

import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from './VuetifyMixin';
import { isCloserToBlackOrWhite } from '@/helpers';

@Component
export class ColorMixin extends Mixins(VuetifyMixin) {
	@Prop({ default: 'blue' }) color: string;

	colorWhite: string = '#FFFFFF';
	colorBlack: string = '#000000';
	
	IsCloserToBlackOrWhite(hex: string, opposite: boolean = false): string {
		return isCloserToBlackOrWhite(hex, opposite);
	}
	
	ComplementaryTextColor(color: string): string {
		const vueColor = this.getColor(color);
		if( this.IsCloserToBlackOrWhite(vueColor) === this.colorWhite ) return 'black--text';
		return 'white--text';
	}	

	ScoutingScoreColor(score: Number) : string {	
		if( Number(score) > 4.25 ) return 'purple darken-3';
		else if( Number(score) > 3.25 ) return 'green darken-4';
		else if( Number(score) > 2.75 ) return 'light-green darken-3';
		else if( Number(score) > 1.75 ) return 'lime darken-3';
		else if( Number(score) > 0.75 ) return 'deep-orange darken-4';
		else if( Number(score) > 0 ) return 'red darken-4';
		return  this.color;
	}

	ScoutingScoreTextColor(score: Number) : string {
		if( Number(score) > 4.25 ) return 'white';
		else if( Number(score) > 3.25 ) return 'white';
		else if( Number(score) > 2.75 ) return 'white';
		else if( Number(score) > 1.75 ) return 'white';
		else if( Number(score) > 0.75 ) return 'white';
		else if( Number(score) > 0 ) return 'white';
		return  this.color;
	}
}