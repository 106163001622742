
import { Component, Prop, Mixins } from 'vue-property-decorator';
import Page from './Page.vue';
import Alert from '../components/ui/Alert.vue';
import SurveySvg from '../components/svg/SurveySvg.vue';
import MultiStepForm from '../components/forms/MultiStepForm.vue';
import { MultiStepFormMixin } from '../mixins/MultiStepFormMixin';
import TeamCreateTeamInfo from '../components/teams/TeamCreateTeamInfo.vue';
import TeamCreateDetailInfo from '../components/teams/TeamCreateDetailInfo.vue';
import TeamCreateStaffInfo from '../components/teams/TeamCreateStaffInfo.vue';
import TeamCreateTeamRoster from '../components/teams/TeamCreateTeamRoster.vue';
import { TeamModel } from '../models/team';
import { TeamDashboard, CoachDashboard } from '@/../types/constants/web_client_user.routes';

import { teamCreateStore, teamsStore } from '../store';
import { StaffMember } from '../models/team/StaffMember';
import { SportModel } from '@/models/sport';
import { SportName } from '@/../types/enums';
import { AuthMixin, MyCoachMixin, StringsMixin, BAIconsMixin } from '@/mixins';
import { TeamCreateFormValue, TeamCreateOptions } from '@/../types/interfaces';
import { OrganizationModel } from '@/models/organization/OrganizationModel';
import { organizationApi } from '@/api/OrganizationApi';
import { sportApi } from '@/api/SportApi';

@Component({
	components: {
		Alert,
		MultiStepForm,
		Page,
		SurveySvg,
		TeamCreateTeamInfo,
		TeamCreateDetailInfo,
		TeamCreateStaffInfo,
		TeamCreateTeamRoster,
	},
})
export default class TeamCreate extends Mixins(MultiStepFormMixin, AuthMixin, MyCoachMixin, StringsMixin, BAIconsMixin){
	@Prop({ default: false }) editAll: boolean;
	@Prop({ default: false }) selfRoleOptional: boolean;

	errorMessage: string | null = null;
	async mounted() {
		await this.resetForm();
		await this.loadOrganization();
		await this.loadTeam();
	}

	get OrgId(): string {
		return this.$route.params.organizationId
	}
	get TeamId(): string {
		return this.$route.params.teamId;
	}
	get IsEdit(): boolean{
		return this.IsNotEmpty(this.editTeam);
	}

	organization: OrganizationModel;
	async loadOrganization() {
		if( this.IsEmpty(this.OrgId) ) return;

		this.organization = await organizationApi.findById(this.OrgId);
		if( !this.IsEdit && this.IsNotEmpty(this.organization) ) {
			this.formValue.step1.organizationName = this.organization.name;
			this.formValue.step1.city = this.organization.city;
			this.formValue.step1.country = this.organization.country;

			const sport: SportModel = await sportApi.findByName(this.organization.sport);
			if( this.IsNotEmpty(sport) ) this.formValue.step1.sportId = sport.name;

			if( this.IsNotEmpty(this.organization.jerseyPrimary) ) this.formValue.step1.jerseyPrimary = this.organization.jerseyPrimary;
			if( this.IsNotEmpty(this.organization.jerseyAlternate) ) this.formValue.step1.jerseyAlternate = this.organization.jerseyAlternate;
			if( this.IsNotEmpty(this.organization.jerseyOther) ) this.formValue.step1.jerseyOther = this.organization.jerseyOther;
		}
	}

	formValue: TeamCreateFormValue;
	editTeam: TeamModel | null = null;
	async loadTeam() {
		const teamId = this.$route.params.teamId;
		this.editTeam = await teamsStore.loadTeamById(teamId);
		if( this.IsEmpty(this.editTeam) ) return;

		this.goBackWithPrevious = false;
		this.formValue.step1.name = this.editTeam.name;
		this.formValue.step1.country = this.editTeam.country;
		this.formValue.step1.city = this.editTeam.city;
		this.formValue.step1.gender = this.editTeam.gender;
		this.formValue.step1.seasonFormat = this.editTeam.seasonFormat;
		this.formValue.step1.seasonYear = this.editTeam.seasonYear;
		this.formValue.step1.logoUrl = this.editTeam.logoUrl;
		this.formValue.step1.division = this.editTeam.division;
		this.formValue.step1.sportId = this.editTeam.sportId;
		this.formValue.step1.jerseyPrimary = this.editTeam.jerseyPrimary;
		this.formValue.step1.jerseyAlternate = this.editTeam.jerseyAlternate;
		this.formValue.step1.jerseyOther = this.editTeam.jerseyOther;

		this.formValue.teamDetails.bio = this.editTeam.bio;
		this.formValue.teamDetails.teamUrl = this.editTeam.teamUrl;
		this.formValue.teamDetails.teamCalendarURL= this.editTeam.teamCalendarURL;
		this.formValue.teamDetails.honors = this.editTeam.honors;
		this.formValue.teamDetails.notable = this.editTeam.notable;

		this.formValue.teamStaff.staffMembers = this.editTeam.staffMembers;

		this.formValue.step3.players = this.editTeam.players;

		if( this.IsNotEmpty(this.organization) ) {
			if( this.IsEmpty(this.formValue.step1.jerseyPrimary) ) this.formValue.step1.jerseyPrimary = this.organization.jerseyPrimary;
			if( this.IsEmpty(this.formValue.step1.jerseyAlternate) ) this.formValue.step1.jerseyAlternate = this.organization.jerseyAlternate;
			if( this.IsEmpty(this.formValue.step1.jerseyOther) ) this.formValue.step1.jerseyOther = this.organization.jerseyOther;
		}
	}
	async resetForm() {
		this.formValue = this.defaultForm();

		if( this.IsEmpty(this.formValue.step1.jerseyPrimary) ) this.formValue.step1.jerseyPrimary = this.organization?.jerseyPrimary;
		if( this.IsEmpty(this.formValue.step1.jerseyAlternate) ) this.formValue.step1.jerseyAlternate = this.organization?.jerseyAlternate;
		if( this.IsEmpty(this.formValue.step1.jerseyOther) ) this.formValue.step1.jerseyOther = this.organization?.jerseyOther;
	}

	newTeamId: string = "";
	formComplete: boolean = false;
	get Loading(): boolean{
		return teamCreateStore.createTeamLoading || teamsStore.loadTeamByIdLoading;
	}
	steps = 4;
	currentStep = 1;

	get CurrentStepValid(): boolean {
		const step = this.formValue[this.CurrentStepKey];
		return step ? step.valid : true;
	}

	get Progress(): number{
		switch(this.currentStep){
		case 1: return 10;
		case 2: return 40;
		case 3: return 60;
		case 4: return 80;
		default: return 100;
		}
	}

	defaultForm = (): TeamCreateFormValue => ({
		step1: {
			valid: true,
			name: "",
			country: "",
			city: "",
			sportId: SportName.Soccer,
			gender: "",
			seasonFormat: "",
			seasonYear: new Date().getFullYear(),
			seasonStartDate: "",
			seasonEndDate: "",
			organizationTypeId: "",
			organizationName: "",
			logoUrl: null,
			division: "",
			jerseyPrimary: undefined,
			jerseyAlternate: undefined,
			jerseyOther: undefined,
		},
		teamDetails: {
			valid: true,
			teamUrl: null,
			teamCalendarURL: null,
			colors: "",
			bio: "",
			honors: "",
			notable: "",
		},
		teamStaff: {
			valid: true,
			myRole: "",
			makeEmailPublic: false,
			staffMembers: [],
			sendStaffInvitations: false,
		},
		step3: {
			valid: true,
			players: [],
			invitePlayers: [],
			sendPlayerInvitations: false,
		}
	});

	getErrors(error: Record<string, any>): string[][]{
		return Object.values(error).filter(x => x.length !== 0)
	}

	async finish(validate: () => Promise<boolean>): Promise<void>{
		// Validate the form
		if (!(await validate())) {
			this.errorMessage = 'The follow field(s) must be completed:'
			window.scrollTo(0, 0)
			return;
		}

		let team: TeamModel;
		if( this.IsEdit ) {
			team = teamsStore.myTeams.find(t => t.id === this.TeamId);
		}
		if(team === undefined){
			team = new TeamModel();
		}

		const {
			name,
			country,
			city,
			sportId,
			gender,
			seasonFormat,
			seasonYear,
			organizationTypeId,
			organizationName,
			logoUrl,
			division,
		} = this.formValue.step1;
		team.name = name;
		team.country = country;
		team.city = city;
		team.sportId = sportId;
		team.gender = gender;
		team.seasonFormat = seasonFormat;
		team.seasonYear = seasonYear;
    	team.organizationId = this.$route.params.organizationId;
		team.organizationTypeId = organizationTypeId;
		team.organizationName = organizationName;
		team.logoUrl = logoUrl;
		team.division = division;

		team.bio = this.formValue.teamDetails.bio;
		team.teamUrl = this.formValue.teamDetails.teamUrl;
		team.teamCalendarURL = this.formValue.teamDetails.teamCalendarURL;
		team.honors = this.formValue.teamDetails.honors;
		team.notable = this.formValue.teamDetails.notable;

		team.jerseyPrimary = this.formValue.step1.jerseyPrimary;
		team.jerseyAlternate = this.formValue.step1.jerseyAlternate;
		team.jerseyOther = this.formValue.step1.jerseyOther;

		const staffMembers: StaffMember[] = this.formValue.teamStaff.staffMembers
		const sendStaffInvitations = this.formValue.teamStaff.sendStaffInvitations;

		team.staffMembers = [...staffMembers];

		const { players, invitePlayers, sendPlayerInvitations } = this.formValue.step3;

		team.players = [
			...players,
			...invitePlayers,
		];

		const opts: TeamCreateOptions = {
			invitePlayers: sendPlayerInvitations,
			inviteStaff: sendStaffInvitations,
			sourceCoachId: this.MyCoachId,
		};

		if( !this.IsEdit ){
			const newTeam = await teamCreateStore.createTeam({team, opts});
			this.formComplete = true;
			this.newTeamId = newTeam.id;
			teamsStore.saveTeam({ team: newTeam });
		}else{
			team.id = this.TeamId;
			const newTeam = await teamCreateStore.patchTeam({team, opts});
			this.formComplete = true;
			this.newTeamId = newTeam.id;
			teamsStore.saveTeam({ team: newTeam });
		}
		this.errorMessage = null;
		this.viewTeamProfile();
	}
	cancel(): void{
		this.errorMessage = null;
		this.goToDashboard();
	}

	@Prop({ default: null }) teamDashboardRoute: string | null;
	get TeamDashboard(): string{
		if(this.teamDashboardRoute !== null) return this.teamDashboardRoute;
		return TeamDashboard;
	}
	@Prop({ default: null }) dashboardRoute: string | null;
	get Dashboard(): string{
		if(this.dashboardRoute !== null) return this.dashboardRoute;
		return CoachDashboard;
	}

	viewTeamProfile(): void{
		this.$router.push({
			name: this.TeamDashboard,
			params: { teamId: this.newTeamId },
		});
	}
	goToDashboard(): void{
		this.$router.push({ name: this.Dashboard });
	}
}
