import { RoleName } from '../enums';

/**
 * Roles which can access the internal admin app
 */
export const AdminAppRoles: RoleName[] = [
	RoleName.Admin,
	RoleName.EventAdmin,
	RoleName.UserAdmin,
];

/**
 * Roles which can access the club admin app
 */
export const ClubAdminAppRoles: RoleName[] = [
	RoleName.Admin,
	RoleName.ClubAdmin,
];

/**
 * Roles which can access the club admin app
 */
export const UserAdminAppRoles: RoleName[] = [
	RoleName.Admin,
	RoleName.UserAdmin,
];

/**
 * Roles which can access the club admin app
 */
export const EventAdminAppRoles: RoleName[] = [
	RoleName.Admin,
	RoleName.EventAdmin,
];

export const AllAdminAppRoles: RoleName[] = [
	RoleName.Admin,
	RoleName.ClubAdmin,
	RoleName.EventAdmin,
	RoleName.UserAdmin,
];