/* RoutingMixin.ts */

import { httpUrl } from '@/helpers';
import { isEmpty } from '@/pipes';
import { Component, Mixins } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { AppHostnameMixin, StringsMixin } from '@/mixins';
import { AthleteProfileModel } from '@/models';

@Component
export class RoutingMixin extends Mixins(StringsMixin, AppHostnameMixin) {
    
    gotoUrl(url: string, dest: string = "_blank") {
		if( isEmpty(url) ) return;
		if( !url.includes('https') ) {
			url = httpUrl(url);
		}
        window.open(url, dest);
    }

	gotoHTTP(url: string, page: string, dest: string = "_blank") {
		const http: string = httpUrl(url, page);
		this.gotoUrl(http, dest);
	}
	getRoute(name: string, params = { ...this.$route.params } ): Partial<Route> {
		return { name, params }
	}

	async gotoRoute(route: Partial<Route>) {
		this.$router.push(route);
		this.$vuetify.goTo(0);
	}

	gotoViewAthleteProfile(athlete: AthleteProfileModel) {
		if( this.IsEmpty(athlete)) return;
		this.gotoHTTP(this.PublicAppUrl, athlete.PublicSharingUrl);
	}
	gotoViewAthleteTeam(athlete: AthleteProfileModel) {
		if( this.IsEmpty(athlete?.CurrentTeam)) return;
		this.gotoHTTP(this.PublicAppUrl, `/team/${athlete.CurrentTeam.id}/${athlete.CurrentTeam.sharingUrlId}`);
	}

}
