
import { Component, Mixins, Watch, PropSync, Prop } from 'vue-property-decorator';
import { VuetifyMixin, BAIconsMixin } from '@/mixins';
import { AthleteProfileModel } from '@/models/athlete/AthleteProfileModel';
import { athleteApi } from '@/api/AthleteApi';
import { DebounceMixin } from '@/mixins';

@Component
export default class AdminAthleteAutocomplete extends Mixins(DebounceMixin, VuetifyMixin, BAIconsMixin){
	search: string = "";

	@PropSync('value') athlete: AthleteProfileModel | null;
	@Prop({ default: "Athletes" }) label: string;
	@Prop({ default: "Start typing to search" }) placeholder: string;
	@Prop({ default: 6 }) limit: number;
	@Prop({ type: Boolean }) dense;
	@Prop({ type: Boolean }) hideDetails;
	@Prop({ default: 20 }) avatarSize: number;
	@Prop({ type: Boolean }) showId;
	@Prop({ type: Boolean }) showDeleted;

	private athletes: AthleteProfileModel[] = [];

	get Athletes(): AthleteProfileModel[] {
		return this.athletes;
	}
	
	searchLoading: boolean = false;
	@Watch('search') debounceSearchAthletes(): void{
		this.searchLoading = true;
		this.debounceCallback('searchAthletes', this.searchAthletes.bind(this), 500);
	}

	async searchAthletes(): Promise<void>{
		this.searchLoading = true;
		try{
			const athleteSearch = await athleteApi.queryAll({
				search: this.search,
				fields: [
					'id',
					'firstName',
					'lastName',
					'email',
				]
			},
			{
				limitPerPage: this.limit,
			});
			if( this.showDeleted ) this.athletes = athleteSearch.docs;
			else this.athletes = athleteSearch.docs.filter(a => a.deleted === undefined || !a.deleted);
		}catch(e){
			console.error('Failed to query athletes', e);
		}finally{
			this.searchLoading = false;
		}
	}
}
