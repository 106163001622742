
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BAIconsMixin, StringsMixin, VuetifyMixin } from '@/mixins';
import FormAddArea from '../forms/FormAddArea.vue';
import FileUploadArea from '../forms/FileUploadArea.vue';
import MonthPicker from '../forms/MonthPicker.vue';
import { SportsSelectorMixin, CountriesSelectorMixin } from '@/mixins/SelectorMixins';
import { DatePickerAllowedDatesFunction } from 'vuetify';
import { TeamJersey } from '@/models';
import JerseyEditColor from './JerseyEditColor.vue'
import { OrganizationModel } from '@/models/organization/OrganizationModel';
import { organizationApi } from '@/api/OrganizationApi';

@Component({
	components: { FormAddArea, FileUploadArea, MonthPicker, JerseyEditColor },
})
export default class TeamCreateTeamInfo extends Mixins(VuetifyMixin, BAIconsMixin, StringsMixin, SportsSelectorMixin, CountriesSelectorMixin){
	showLinkOrgDialog: boolean = false;
	thisYear: number = new Date().getFullYear();
	organization: OrganizationModel;

	@Prop({ type: Boolean}) isEdit;
	@Prop() organizationId: string;
	@Prop() value = {
		valid: false,
		name: "",
		country: "",
		city: "",
		sportId: "",
		gender: "",
		seasonFormat: "year",
		seasonYear: this.thisYear,
		seasonStartDate: null,
		seasonEndDate: null,
		organizationTypeId: "",
		organizationName: "",
		division: "",
		logoUrl: null,
		jerseyPrimary: {
			label: 'Primary',
			color: '#FF0000FF',
			accent: "#FFFFFFFF",
			number: "#FFFFFFFF",
		},
		jerseyAlternate: {
			label: 'Alternate',
			color: '#0000BBFF',
			accent: "#FFFFFFFF",
			number: "#FFFFFFFF",
		},
		jerseyOther: {
			label: 'Goalkeeper',
			color: '#007F0EFF',
			accent: "#FFFFFFFF",
			number: "#FFFFFFFF",
		},
	};
	input(): void{
		this.$emit('input', this.value);
	}
	async mounted(): Promise<void> {
		this.organization = await organizationApi.findById(this.organizationId);		
	}

	requiredRule = [
		(v: any): boolean | string => !!v || 'Required',
	];

	$refs:{
		form: HTMLFormElement;
	}

	organizationDialog = {
		name: "",
		typeId: "",
	}

	get SeasonYears(): number[]{
		return new Array(6).fill(0).map((_, i) => this.thisYear - 2 + i);
	}

	async submit($event: Event): Promise<void> {
		$event.preventDefault();
		this.$refs.form.validate();
		if(this.value.valid === false) return;
		this.$emit('submit', this.value);
	}

	get EndAllowedDates(): DatePickerAllowedDatesFunction{
		if(!this.value.seasonStartDate) return () => true;
		return (date: string) => {
			const [ year, month ] = date.split('-');
			return (+year >= this.value.seasonStartDate.getUTCFullYear()) && (+month > this.value.seasonStartDate.getUTCMonth());
		}
	}

	isEditColorsAvailable: boolean = true;
	isColorsChanged: boolean = false;
	backupJerseyPrimary: TeamJersey;
	backupJerseyAlternate: TeamJersey;
	backupJerseyOther: TeamJersey;
	get IsOrgCopyAvailable(): boolean {
		if( this.IsEmpty(this.organizationId) ) return false;
		return( this.isEditColorsAvailable && (
			( this.value.jerseyPrimary != this.organization?.jerseyPrimary ) ||
			( this.value.jerseyAlternate != this.organization?.jerseyAlternate ) ||
			( this.value.jerseyOther != this.organization?.jerseyOther ) 
		));
	}
	onUseClubColors() {
		this.isEditColorsAvailable = false;
		this.onStartChangeJerseyColor();
		this.value.jerseyPrimary = this.organization.jerseyPrimary;
		this.value.jerseyAlternate = this.organization.jerseyAlternate;
		this.value.jerseyOther = this.organization.jerseyOther;
		this.isColorsChanged = true;
		this.isEditColorsAvailable = true;
	}
	onStartChangeJerseyColor() {
		this.backupJerseyPrimary = {...this.value.jerseyPrimary};
		this.backupJerseyAlternate = {...this.value.jerseyAlternate};
		this.backupJerseyOther = {...this.value.jerseyOther};
	}
	onAcceptChangeJerseyColor() {
		this.isColorsChanged = true;
	}
	onRestoreColors() {
		this.isEditColorsAvailable = false;
		this.value.jerseyPrimary = this.backupJerseyPrimary;
		this.value.jerseyAlternate = this.backupJerseyAlternate;
		this.value.jerseyOther = this.backupJerseyOther;
		this.isColorsChanged = false;
		this.isEditColorsAvailable = true;
	}

	editingColors: boolean = false;
}
